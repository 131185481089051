import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import Context from "../../components/Context";
import Button from "../../components/UI/Form/Button/Button";

import firebase from "./firebaseStartup";

// // Firebase App (the core Firebase SDK) is always required and must be listed first
// import firebase from "firebase/app";
// // If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// // import * as firebase from "firebase/app"

// // If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

// // Add the Firebase products that you want to use
// import "firebase/auth";
// import "firebase/firestore";

// // TODO: Replace the following with your app's Firebase project configuration
// // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
// const firebaseConfig = {
//   apiKey: "AIzaSyCXWbW5k8G5x9B5gdxlPH5bUTllUXORqvQ",
//   authDomain: "loss-ilahleko.firebaseapp.com",
//   projectId: "loss-ilahleko",
//   storageBucket: "loss-ilahleko.appspot.com",
//   messagingSenderId: "161085520963",
//   appId: "1:161085520963:web:802a308a1add5d894ca8f6",
//   measurementId: "G-6YHLV60MNC",
// };

// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

function Signin() {
  const formSchema = {
    email: "",
    password: "",
  };

  const [formFields, setFormFields] = useState(formSchema);
  const [isUserResolved, setisUserResolved] = useState(false);

  const { LINKURL, setuserActive } = useContext(Context);

  function handleSubmit(e) {
    e.preventDefault();
    setFormFields(formSchema);
    setisUserResolved(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(formFields.email, formFields.password)
      .then((userCredential) => {
        var user = userCredential.user;
        setuserActive(user);
        setFormFields(formSchema);
        setisUserResolved(false);
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage, errorCode);
      });
  }

  return (
    <div className={"SigupForm"}>
      <div className={"SigupForm--Wrapper"}>
        <nav>
          <div className={"Nav-Branding"}>
            <Link to={"/"}>
              <img
                src={`${LINKURL}/images/Loss-iLahleko-Logo.png`}
                alt={"Loss iLahleko Logo"}
              />
            </Link>
          </div>

          <div className={"Nav-Branding-Contents"}>
            <p>Don't have an account?</p>
            <Button value={"Get Started"} buttonStyle={""} type={"text"} />
          </div>
        </nav>
        <div className={"form--header"}>
          <div className={"Brand--Logo"}></div>
          <h1>Login to your Loss-iLahleko Account</h1>
          <h3 style={{ fontWeight: "lighter", fontStyle: "italic" }}>
            Welcome, enter your details below
          </h3>
        </div>
        <div className={"SigupForm--Content--Wrapper"}>
          <form onSubmit={handleSubmit}>
            <label htmlFor={"Email"}>Email Address</label>
            <input
              className={""}
              placeholder={"name@yourdomain.com"}
              value={formFields.email}
              onChange={(e) => {
                setFormFields({ ...formFields, email: e.target.value });
              }}
              required
              type={"email"}
            />
            <label htmlFor={"Password"}>Password</label>
            <input
              className={""}
              placeholder={"Password"}
              value={formFields.password}
              onChange={(e) => {
                setFormFields({ ...formFields, password: e.target.value });
              }}
              required
              type={"password"}
            />
            <p
              style={{
                textAlign: "right",
                textStyle: "italic",
                fontSize: "small",
              }}
            >
              Forgot password?
            </p>
            {!isUserResolved ? (
              <Button
                style={{ margin: "auto", display: "grid" }}
                value={"Signin"}
                buttonStyle={""}
                type={"submit"}
              />
            ) : (
              <Button
                style={{ margin: "auto", display: "grid", opacity: ".2" }}
                value={"Signin"}
                buttonStyle={""}
                disabled
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signin;
