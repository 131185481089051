import React from "react";
import Form from "./form/Form";
import Map from "./map/Map";

function ContactUs() {
  return (
    <div className={"Contact--Page--Wrapper"}>
      <div className={"Contact--Page--Contents"}>
        <Form />
        <Map />
      </div>
    </div>
  );
}

export default ContactUs;
