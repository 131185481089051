import React, { useContext } from "react";
import Context from "../components/Context";
import ADContent from "../section/ADContent";
import MainBanner from "../section/banner/MainBanner";
import ContactUs from "../section/contactUs/ContactUs";
import FeaturedWritters from "../section/featuredWriters/FeaturedWritters";
import Footer from "../section/footer/Footer";
import OurStoryExcept from "../section/ourStory/OurStoryExcept";
import SocialMedia from "../section/socialMedia/SocialMedia";
import Statistics from "../section/statistics/Statistics";

function HomePage() {
  const { AnimeCSS } = useContext(Context);
  return (
    <div className={AnimeCSS()}>
      <MainBanner />
      <OurStoryExcept />
      <Statistics />
      <FeaturedWritters />
      <ADContent />

      <ContactUs />
      <SocialMedia />
      <Footer />
    </div>
  );
}

export default HomePage;
