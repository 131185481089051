import React from "react";
import "./Button.css";

function Button({ value, children, type, onClick, buttonStyle, style }) {
  const checkButtonStyle = Styles.includes(buttonStyle)
    ? buttonStyle
    : Styles[0];

  return (
    <button
      onClick={onClick}
      type={type}
      style={style}
      className={checkButtonStyle}
    >
      {children || value}
    </button>
  );
}

const Styles = [
  "btn--defualt",
  "btn--gradient",
  "btn--gradient--form",
  "btn--gradient--play",
  "subscribed",
  "memberExists",
  "pending",
  "failed",
];

export default Button;
