import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Context from "../components/Context";
import Footer from "../section/footer/Footer";
import SocialMedia from "../section/socialMedia/SocialMedia";

function GenericPost() {
  const { pages, isLoaded, AnimeCSS } = useContext(Context);

  let { id } = useParams();

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#181818" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#181818" }} />
      </div>
    );
  }

  const Header = pages.map(function (params) {
    const { slug, title, _embedded } = params;

    const checkMatch = id === slug;
    if (checkMatch) {
      return (
        <div key={slug} style={{ height: "100%" }}>
          <div style={{ height: "30vh", position: "relative" }}>
            <img
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              src={_embedded["wp:featuredmedia"][0].source_url}
              alt={slug}
            />
            <h1>{title.rendered}</h1>
          </div>
        </div>
      );
    }
    return null;
  });

  const Body = pages.map(function (params) {
    const { slug, content, date } = params;

    const checkMatch = id === slug;
    if (checkMatch) {
      return (
        <div
          key={date}
          className={"Our--Story--Content"}
          dangerouslySetInnerHTML={{ __html: content.rendered }}
        />
      );
    }
    return null;
  });

  return (
    <div className={AnimeCSS()}>
      <div className={"Main--Banner--Wrapper Main--Banner--GP--Wrapper"}>
        {isLoaded ? <PreLoader /> : Header}
      </div>

      <div className={"Content--Wrapper"}>{isLoaded ? PreLoader() : Body}</div>

      <SocialMedia />
      <Footer />
    </div>
  );
}

export default GenericPost;
