import React from "react";

import FBIcon from "../../icons/facebook.svg";
// import TWIcon from "../../icons/twitter.svg";
// import INSTAIcon from "../../icons/instagram.svg";
// import YTIcon from "../../icons/youtube.svg";
import MAILIcon from "../../icons/mail2.svg";

function SocialMedia() {
  return (
    <div className={"Content--Wrapper"}>
      <h1>Follow us on</h1>
      <div className={"Social--Media--Icon--Wrapper"}>
        <ul className={"Social--Media--Icon"}>
          <a
            href="https://www.facebook.com/loss.ilahleko/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li>
              <img src={FBIcon} alt={""} />
            </li>
          </a>
          {/* <a href="/" target="_blank" rel="noopener noreferrer">
            <li>
              <img src={TWIcon} alt="" />
            </li>
          </a>

          <a href="/" target="_blank" rel="noopener noreferrer">
            <li>
              <img src={INSTAIcon} alt="" />
            </li>
          </a>

          <a href="/" target="_blank" rel="noopener noreferrer">
            <li>
              <img src={YTIcon} alt="" />
            </li>
          </a> */}

          <a href="mailto:info@loss-ilahleko.org" rel="noopener noreferrer">
            <li>
              <img src={MAILIcon} alt="" />
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
}

export default SocialMedia;
