// import {
//   ArrowBackIosOutlined,
//   ArrowForwardIosOutlined,
// } from "@material-ui/icons";
import React, { useContext } from "react";
import Slider from "react-slick";

import Button from "../../components/UI/Form/Button/Button";
import { Link, useLocation } from "react-router-dom";
import Context from "../../components/Context";
import Player from "../../components/Player/Player";

function MainBanner() {
  const { pathname } = useLocation();
  const { mainBanner, isLoaded, LINKURL } = useContext(Context);

  const cat = [
    "", //0
    "afrikaans", //1
    "", //2
    "xhosa", //3
    "english", //4
    "", //5
    "sesotho", //6
    "ndebele", //7
    "swazi", //8
    "zulu", //9
    "xitsonga", //10
    "sepedi", //11
    "tswana", //12
    "venda", //13
    "cover artist", //14
  ];

  const MainBannerApiMap = mainBanner.map(function (menuData, i) {
    const { title, excerpt, _embedded, categories, id, acf } = menuData;

    const audioSRC = () => {
      const { poem_1, poem_2, poem_3 } = acf;
      if (poem_1 !== false || poem_2 !== false || poem_3 !== false) {
        return poem_1;
      }
      return null;
    };

    const catMap = cat.map(function (a, index) {
      const match = index === categories[0];
      const catIndex = index;

      const contentType = () => {
        if (acf?.select_content_type?.toLowerCase() === "poetry") {
          return <span>Poem</span>;
        } else {
          return <span>Story</span>;
        }
      };

      if (match) {
        if (cat !== undefined) {
          return (
            <div key={index} className={"Main--Banner--Slider--Title"}>
              <h1>{cat[`${catIndex}`]}</h1>
              {contentType()}
            </div>
          );
        }
      }
      return null;
    });

    return (
      <div
        key={id}
        className={"Main--Banner--Slider"}
        tabIndex={"-1"}
        style={{ width: "100%", display: "inline-block" }}
      >
        <div className={"Content--Wrapper Content--Wrapper--stats"}>
          <div className={"Main--Banner--Content--Wrapper"}>
            <div className={"Main--Banner--Content"}>
              <Link
                to={{
                  pathname: `${LINKURL}/The-national-Choreopoem/writer/${title.rendered}`,
                  state: { prevPath: pathname },
                }}
              >
                <h1 dangerouslySetInnerHTML={{ __html: title.rendered }} />
              </Link>
              <div dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
              <div className={"Main--Banner--Artist--Play--wrapper"}>
                {audioSRC() !== null ? (
                  <Player audioSRC={audioSRC()} i={i} acf={acf} />
                ) : null}

                <Link
                  to={{
                    pathname: `${LINKURL}/The-national-Choreopoem/writer/${title.rendered}`,
                    state: { prevPath: pathname },
                  }}
                >
                  {audioSRC() !== null ? (
                    <Button
                      style={{ marginLeft: "10px", backgroundColor: "#d4b095",color:"#fff" }}
                      value={"Read more"}
                    />
                  ) : (
                    <Button style={{ marginLeft: "0" }} value={"Read more"} />
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={"Main--Banner--Slider--Artist--Wrapper"}>
          <img src={_embedded["wp:featuredmedia"][0].source_url} alt={""} />
          {catMap}
        </div>
      </div>
    );
  });

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
  };

  // function NextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={`Main--Banner--nav Next ${className}`}
  //       style={{ ...style, left: "unset", right: "0" }}
  //       onClick={onClick}
  //     >
  //       <ArrowForwardIosOutlined color={"inherit"} fontSize={"large"} />
  //     </div>
  //   );
  // }

  // function PrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={`Main--Banner--nav Prev  ${className}`}
  //       style={{ ...style }}
  //       onClick={onClick}
  //     >
  //       <ArrowBackIosOutlined color={"inherit"} fontSize={"large"} />
  //     </div>
  //   );
  // }

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#181818" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
      </div>
    );
  }

  return (
    <div className={"Main--Banner--Wrapper"}>
      {isLoaded ? (
        <PreLoader />
      ) : (
        <Slider {...settings}>{MainBannerApiMap.reverse()}</Slider>
      )}
    </div>
  );
}

export default MainBanner;
