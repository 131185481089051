import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import Context from "../components/Context";
import Footer from "../section/footer/Footer";
import ImageViewerModal from "../components/Modal/ImageViewerModal";
import axios from "axios";
import { Avatar, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "../components/UI/Form/Button/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: "60%",
    backgroundColor: "#d8d8d8",
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

function BlogSingle() {
  const classes = useStyles();
  const {
    isLoading,
    OutReach,
    LINKURL,
    // CheckDeviceWidth,
    AnimeCSS,
    imageModalOn,
    setimageModalOn,
    setimageToView,
    setimageToViewArray,
    pages,
    APIURL,
  } = useContext(Context);

  const { d } = useParams();
  const { pathname } = useLocation();

  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(d);

  useEffect(() => {
    axios
      .get(`${APIURL}/wp/?rest_route=/wp/v2/heal_blog&per_page=14&_embed`)
      .then(setLoading(true))
      .then((res) => {
        setBlog(res.data);
        setLoading(false);
      });
  }, [APIURL]);

  const history = useHistory();

  useEffect(() => {
    const items = document.querySelectorAll(".blocks-gallery-item img");

    setimageToViewArray([...items]);
    for (let i = 0; i < items.length; i++) {
      items[i].addEventListener("click", (e) => {
        console.log("Image Viewer",e)
        setimageToView(e?.srcElement?.currentSrc);
        setimageModalOn(true);
      });
    }
  }, [setimageModalOn, setimageToView, setimageToViewArray]);

  const OutReachMap = OutReach.map((params, i) => {
    const { content, title } = params;
    const titleFormat = title.rendered.toLowerCase();
    const dtoLowerCase = d.toLowerCase();

    if (titleFormat === dtoLowerCase) {
      return (
        <div key={i}>
          <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
          <button
            onClick={() => {
              history.push(`${LINKURL}/now/shopping`);
            }}
            style={{
              margin: "auto",
              color: "#e2ba41",
              textAlign: "center",
            }}
            type={"submit"}
          >
            BUY A BOOK 
          </button>
        </div>
      );
    } else {
      return null;
    }
  });

  const HealMap = blog.map((params, i) => {
    const { content, title, _embedded, featured_media } = params;

    function checkImgURL() {
      if (featured_media === 0) return;

      console.log("imgURL", _embedded);
      if (featured_media === 0) {
        return "";
      } else {
        return (
          <img
            key={i}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={_embedded["wp:featuredmedia"][0]?.source_url ?? ""}
            alt={title.rendered}
          />
        );
      }
    }

    return (
      <Paper key={isLoading} className={classes.paper}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar>{checkImgURL()}</Avatar>
          </Grid>
          <Grid item xs>
            <Typography gutterBottom variant="h5" component="h2">
              {title?.rendered}
            </Typography>
            <div
              className={"MuiTypography-root MuiTypography-body1 pageP"}
              dangerouslySetInnerHTML={{
                __html: content?.rendered.slice(0, 255),
              }}
            />
            <Link
              to={{
                pathname: `${LINKURL}/blog/${title?.rendered}`,
                state: { prevPath: pathname },
              }}
            >
              <Button style={{ marginTop: "20px" }} value={"Read more"} />
            </Link>
          </Grid>
        </Grid>
      </Paper>
    );
  });

  const HealPageMap = blog.map((params, i) => {
    const { content, title } = params;
    const titleFormat = title.rendered.toLowerCase();
    const dtoLowerCase = d.toLowerCase();

    if (titleFormat === dtoLowerCase) {
      return (
        <div key={i}>
          <h1 className="blog-title" style={{ display: "block" }}>
            {title.rendered}
          </h1>
          <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
        </div>
      );
    } else {
      return null;
    }
  });

  const HealPageImageMap = blog.map((params, i) => {
    const { title, _embedded, featured_media } = params;
    const titleFormat = title.rendered.toLowerCase();
    const dtoLowerCase = d.toLowerCase();

    if (titleFormat === dtoLowerCase) {
      return (
        <div
          key={i}
          style={{
            height: "30vh",
            position: "relative",
            backgroundAttachment: "fixed",
            backgroundPosition: "center center",
            filter: "grayscale(1)",
            backgroundSize: "cover",
          }}
        >
          {featured_media !== 0 ? (
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              src={_embedded["wp:featuredmedia"]?.[0]?.source_url}
              alt={""}
            />
          ) : null}
          <div
            style={{ position: "absolute", top: "0", left: "0" }}
            className={"BG--OL2"}
          />
          {/* <h1
            style={{
              position: "absolute",
              display: "block",
              bottom: "-16px",
              fontSize: "3.8rem",
              left: "50%",
              transform: "translateX(-50%)",
              margin: "auto",
              color: "#fff",
              textTransform: "uppercase",
              width: "fit-content",
            }}
          >
            {title.rendered}
          </h1> */}
        </div>
      );
    } else {
      return null;
    }
  });

  const OutReachImageMap = OutReach.map((params) => {
    const { title, _embedded, id } = params;
    const titleFormat = title.rendered.toLowerCase();
    const dtoLowerCase = d.toLowerCase();
console.log("outreach",dtoLowerCase , titleFormat)
    if (dtoLowerCase === titleFormat) {
      function checkIfImageExist() {
        return (
          <div
            key={id}
            style={{
              height: "30vh",
              position: "relative",
              backgroundAttachment: "fixed",
              backgroundPosition: "center center",
              filter: "grayscale(1)",
              backgroundSize: "cover",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              src={_embedded["wp:featuredmedia"]?.[0]?.source_url}
              alt={""}
            />
            <div
              style={{ position: "absolute", top: "0", left: "0" }}
              className={"BG--OL"}
            />
            <h1
              style={{
                position: "absolute",
                display: "block",
                bottom: "-11px",
                fontSize: "2.5rem",
                left: "50%",
                transform: "translateX(-50%)",
                margin: "auto",
                color: "#fff",
                textTransform: "uppercase",
                width: "fit-content",
              }}
            >
              {title.rendered}
            </h1>
          </div>
        );
      }

      return (
        <div key={id} style={{ height: "100%" }} className={""}>
          {checkIfImageExist()}
        </div>
      );
    }

    return null;
  });

  const HealImageMap = pages.map((params) => {
    const { title, _embedded, id } = params;
    const titleFormat = title.rendered.toLowerCase();
    const dtoLowerCase = d.toLowerCase();

    if (dtoLowerCase === titleFormat) {
      function checkIfImageExist() {
        return (
          <div
            key={id}
            style={{
              height: "30vh",
              position: "relative",
              backgroundAttachment: "fixed",
              backgroundPosition: "center center",
              filter: "grayscale(1)",
              backgroundSize: "cover",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              src={_embedded["wp:featuredmedia"]?.[0]?.source_url}
              alt={""}
            />
            <div
              style={{ position: "absolute", top: "0", left: "0" }}
              className={"BG--OL"}
            />
            <h1
              style={{
                position: "absolute",
                display: "block",
                bottom: "-11px",
                fontSize: "2.5rem",
                left: "50%",
                transform: "translateX(-50%)",
                margin: "auto",
                color: "#fff",
                textTransform: "uppercase",
                width: "fit-content",
              }}
            >
              {title.rendered}
            </h1>
          </div>
        );
      }

      return (
        <div key={id} style={{ height: "100%" }} className={""}>
          {checkIfImageExist()}
        </div>
      );
    }

    return null;
  });

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#fff" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#fff" }} />
      </div>
    );
  }

  return (
    <>
      <div className={AnimeCSS()}>
        <div style={{ height: "30vh" }} className={"Main--Banner--Wrapper"}>
          {pathname !== "/blog/heal" ? (
            isLoading ? (
              <PreLoader />
            ) : (
              OutReachImageMap
            )
          ) : (
            HealImageMap
          )}
          {pathname === `/blog/${d}` ? HealPageImageMap : null}
        </div>
        <div
          className={"Content--Wrapper  cover-artist-content"}
          style={{ display: "grid", alignContent: "center" }}
        >
          {pathname !== "/blog/heal" ? (
            isLoading ? (
              <PreLoader />
            ) : (
              OutReachMap
            )
          ) : loading ? (
            "Loading..."
          ) : (
            HealMap
          )}
          {pathname === `/blog/${d}` ? HealPageMap : null}
        </div>
        <Footer />
      </div>
      {imageModalOn ? <ImageViewerModal /> : null}
    </>
  );
}

export default BlogSingle;
