import React from "react";
import Footer from "../section/footer/Footer";

function Shop() {
  return (
    <>
      <div
        style={{
          height: "100vh",
          // marginTop: "100px",
          marginBottom: "56px",
        }}
      >
        <iframe
          className={"iFrame-Conf"}
          src={"https://loss-ilahleko.org/wp/shop/"}
          style={{ border: "none" }}
          width="100%"
          height="100%"
          title={"Shop"} />
      </div>
      <Footer />
    </>
  );
}

export default Shop;
