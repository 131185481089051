import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Context from "../../../components/Context";

function Map() {
  const [sponsorLogos, setsponsorLogos] = useState([]);
  const { LINKURL } = useContext(Context);

  useEffect(() => {
    axios
      .get(
        `https://loss-ilahleko.org/wp/wp/?rest_route=/wp/v2/sponsor_logos&_embed`
      )
      .then((res) => {
        setsponsorLogos(res.data[0].content.rendered);
      });
  }, [LINKURL]);

  return (
    <div className="Map--content--Wrapper">
      <div className="Map--Wrapper">
        <h1>Sponsors</h1>
        <div
          dangerouslySetInnerHTML={{ __html: sponsorLogos }}
          style={{
            width: "90%",
            paddingTop:"20px",
            margin: "auto",
            filter: "drop-shadow(2px 4px 2px #0000005c)",
          }}
        />
      </div>
    </div>
  );
}

export default Map;
