import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Context from "../components/Context";
import Footer from "../section/footer/Footer";
import SocialMedia from "../section/socialMedia/SocialMedia";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: "auto",
  },
  Margin: {
    // marginTop: 97,
    // marginBottom: 56,
    // padding: 10,
    paddingTop: 100,
  },
  BG: {
    background: "#795438",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

function ArtistAll() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { featuredWriters, AnimeCSS, LINKURL } = useContext(Context);

  const featuredWritersMap = featuredWriters.map((params) => {
    const { title, _embedded, id } = params;
    return (
      <GridListTile className={classes.BG} key={id}>
        <Link
          role={"button"}
          to={{
            pathname: `${LINKURL}/The-national-Choreopoem/writer/${title.rendered}`,
            state: { prevPath: pathname },
          }}
        >
          <img
            style={{
              filter: "drop-shadow(#d4b095 8px 0px 0px)",
              width: "100%",
            }}
            src={_embedded["wp:featuredmedia"][0].source_url}
            alt={title.rendered}
          />
          <GridListTileBar title={title.rendered} />
        </Link>
      </GridListTile>
    );
  });

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{
          background: "transparent",
          height: "100vh",
          margin: "auto",
          alignContent: "center",
          display: "grid",
        }}
      >
        <div className={"LoaderDots"}>
          <span style={{ backgroundColor: "#e2ba41" }} />
          <span style={{ backgroundColor: "#181818" }} />
          <span style={{ backgroundColor: "#e2ba41" }} />
        </div>
      </div>
    );
  }

  const a = () => {
    if (featuredWriters.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {a() ? (
        <PreLoader />
      ) : (
        <>
          <div className={`${classes.root} ${classes.Margin} ${AnimeCSS()}`}>
            <GridList cellHeight={"auto"} className={classes.gridList}>
              {featuredWritersMap}
            </GridList>
            <SocialMedia />
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default ArtistAll;
