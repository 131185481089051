import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Context from "../../components/Context";
import Button from "../../components/UI/Form/Button/Button";

function Footer() {
  const formSchema = {
    email: "",
  };

  const [formFields, setFormFields] = useState(formSchema);
  const [isLoading, setIsLoading] = useState(false);

  const [isToastNotification, setisToastNotification] = useState("");

  const { FooterMenu, isLoaded, LINKURL } = useContext(Context);

  const footerMenuMap = FooterMenu.map(function (footerManuData, i) {
    const { ID, title } = footerManuData;
    const titleFormatedLC = title.toLowerCase();
    const titleFormatedUC = title.toUpperCase();
    if (ID === 90) {
      return (
        <li key={ID}>
          <Link to={`${LINKURL}/blog/outreach/Schools%20Outreach%20Programme`}>
            {titleFormatedUC}
          </Link>
        </li>
      );
    } else {
      return (
        <li key={ID}>
          <Link to={`${LINKURL}/organisation/${titleFormatedLC}`}>
            {titleFormatedUC}
          </Link>
        </li>
      );
    }
  });

  function handleSubmit(e) {
    e.preventDefault();
    setFormFields(formSchema);

    if (formFields) {
      function notF() {
        return setTimeout(() => {
          setisToastNotification();
        }, 5000);
      }
      fetch(
        `http://localhost:9001/api/footer?email=${formFields.email}`
      )
        .then((res) => res.json())
        .then(setIsLoading(true))
        .then((json) => {
          console.log("json",json)
          if (json.status === "subscribed") {
            setIsLoading(false);
            setisToastNotification("subscribed");
            notF();
          } else if (json.title === "Member Exists") {
            setIsLoading(false);
            setisToastNotification("memberExists");
            notF();
          } else if (json.status === "pending") {
            setIsLoading(false);
            setisToastNotification("pending");
            notF();
          } else {
            setisToastNotification("failed");
            setIsLoading(false);
            notF();
          }
        })
        .catch((err) => console.log("err", err));
    }
    // setFormFields(formSchema);
  }

  function notificationTimeoutStyles() {
    if (
      isToastNotification === "subscribed" ||
      "Member Exists" ||
      "pending" ||
      "failed"
    ) {
      return isToastNotification;
    }
  }

  const dateYear = new Date();
  const dateYearRender = dateYear.toISOString().slice(0, 4);

  function PreLoader2() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#fff" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#fff" }} />
      </div>
    );
  }

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span />
        <span />
        <span />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#d4b095",
          color: "#fff",
        }}
      >
        <div className={"Content--Wrapper"}>
          <div className={"Footer--Contents--Wrapper"}>
            <div className={"Footer--Contents Subscribe--Form"}>
              <h2>Sign up for our news letter</h2>
              <form onSubmit={handleSubmit}>
                <input
                  className={notificationTimeoutStyles()}
                  placeholder={"Email Addres"}
                  value={formFields.email}
                  onChange={(e) => {
                    setFormFields({ ...formFields, email: e.target.value });
                  }}
                  required
                  type={"email"}
                />
                <Button
                  value={
                    isLoading ? (
                      <PreLoader />
                    ) : (
                      `Sign up ${
                        notificationTimeoutStyles() === "pending"
                          ? "(check your email)"
                          : ""
                      }`
                    )
                  }
                  buttonStyle={""}
                  type={"submit"}
                  style={{
                    backgroundColor: "#795438",
                    borderRadius: "50px",
                  }}
                />
              </form>
            </div>
            <div className={"Footer--Contents"}>
              <h2>About the organisation</h2>
              {isLoaded ? <PreLoader2 /> : <ul>{footerMenuMap} </ul>}
            </div>
          </div>
        </div>
      </div>
      <div
        className={"Copyright--Contents--Wrapper"}
        style={{ display: "flex", padding: "10px 0px", backgroundColor:"#c19a7d",color:"#000" }}
      >
        <div className="Content--Wrapper p-0">
          <p style={{ width: "100%", fontWeight: "bold" }}>
          © {dateYearRender} Loss-iLahleko. Designed & Developed by Abongile
          Zozi
        </p>
        </div>
        
      </div>
    </>
  );
}

export default Footer;
