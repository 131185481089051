import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Context from "../../components/Context";
import NavItemsContext from "../../components/Context/NavItemsContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function MenuDrawer() {
  const {
    LINKURL,
    toggleDrawer,
    toggleDrawerOff,
    state,
    setState,
  } = useContext(Context);
  const { NavItemsMap } = useContext(NavItemsContext);
  const classes = useStyles();

  const { pathname } = useLocation();

  useEffect(() => {
    setState(false);
  }, [pathname, setState]);

  const ListD = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <div className={"Brand--Logo"}>
        <img src={`${LINKURL}/images/Loss-iLahleko-Logo-A.png`} alt={""} />
      </div>
      <List>{NavItemsMap}</List>
    </div>
  );

  return (
    <Drawer className={classes.fullList} open={state} onClose={toggleDrawerOff}>
      <ListD />
    </Drawer>
  );
}
