import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import Context from "../../components/Context";
import Search from "../../components/Modal/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 0,
  },
  toolbar: {
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    marginBottom: "-24px",
  },
}));

function MobileNav() {
  const { toggleDrawer, LINKURL, handleChangeSIcon } = useContext(Context);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Search />
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <IconButton
            onClick={toggleDrawer}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h5" noWrap>
            <div className="Bran-d--Logo">
              <Link to={`${LINKURL}/`}>
                <img
                  style={{
                    width: "100%",
                    padding: "15px",
                    paddingBottom: "0",
                    boxSizing: "border-box",
                  }}
                  src={`${LINKURL}/images/Loss-iLahleko-Logo-A.png`}
                  alt=""
                />
              </Link>
            </div>
          </Typography>
          <IconButton
            onClick={handleChangeSIcon}
            aria-label="search"
            color="inherit"
          >
            <SearchIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default MobileNav;
