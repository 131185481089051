import React, { useContext, useState } from "react";
import Context from "../../../components/Context";
import Button from "../../../components/UI/Form/Button/Button";

function Form() {
  const formSchema = {
    name: "",
    lastName: "",
    email: "",
    message: "",
  };

  const [formFields, setFormFields] = useState(formSchema);
  const [isLoading, setIsLoading] = useState(false);

  const [isToastNotification, setisToastNotification] = useState("");
  const { LINKURL } = useContext(Context);

  function handleSubmit(e) {
    e.preventDefault();
    setFormFields(formSchema);

    if (formFields !== "") {
      function notF() {
        return setTimeout(() => {
          setisToastNotification();
        }, 5000);
      }

      fetch(
        `https://loss-ilahleko-cors.herokuapp.com/https://loss-ilahleko.herokuapp.com/api/footer?email=${formFields.email}&name=${formFields.name}&lastName=${formFields.lastName}&message=${formFields.message}`
      )
        .then((res) => res.json())
        .then(setIsLoading(true))
        .then((json) => {
          if (json.status === "subscribed") {
            setIsLoading(false);
            setisToastNotification("subscribed");
            notF("");
          } else if (json.title === "Member Exists") {
            setIsLoading(false);
            setisToastNotification("memberExists");
            notF("");
          } else if (json.status === "pending") {
            setIsLoading(false);
            setisToastNotification("pending");
            notF();
          } else {
            setisToastNotification("failed");
            setIsLoading(false);
            notF();
          }
        })
        .catch((err) => console.log(err));
    }
    setFormFields(formSchema);
  }

  function PreLoader() {
    return (
      <div className="loader2" style={{ background: "transparent" }}>
        <span />
        <span />
        <span />
      </div>
    );
  }

  function notificationTimeoutStyles() {
    if (
      isToastNotification === "subscribed" ||
      "Member Exists" ||
      "pending" ||
      "failed"
    ) {
      return isToastNotification;
    }
  }

  return (
    <div className={"Form--Wrapper"}>
      <div className={"form--header"}>
        <div className={"Brand--Logo"}>
          <img
            src={`${LINKURL}/images/Loss-ilahleko-Logo-2.png`}
            alt={"Loss iLahleko Logo"}
          />
        </div>
        <h1>Want to contribute?</h1>
        <h3 style={{ fontWeight: "lighter", fontStyle: "italic" }}>
          get in touch with us
        </h3>
      </div>
      <div className={"Form--Content--Wrapper"}>
        <form onSubmit={handleSubmit}>
          <label htmlFor={"Name"}>Name</label>
          <input
            className={notificationTimeoutStyles()}
            placeholder={"Name"}
            value={formFields.name}
            onChange={(e) => {
              setFormFields({ ...formFields, name: e.target.value });
            }}
            required
            type={"text"}
          />
          <label htmlFor={"Surname"}>Surname</label>
          <input
            className={notificationTimeoutStyles()}
            placeholder={"Surname"}
            value={formFields.lastName}
            onChange={(e) => {
              setFormFields({ ...formFields, lastName: e.target.value });
            }}
            required
            type={"text"}
          />
          <label htmlFor={"Email"}>Email Address</label>
          <input
            className={notificationTimeoutStyles()}
            placeholder={"Email Addres"}
            value={formFields.email}
            onChange={(e) => {
              setFormFields({ ...formFields, email: e.target.value });
            }}
            required
            type={"email"}
          />
          <label htmlFor={"Message"}>Message</label>
          <textarea
            className={notificationTimeoutStyles()}
            placeholder={"Message"}
            value={formFields.message}
            onChange={(e) => {
              setFormFields({ ...formFields, message: e.target.value });
            }}
          ></textarea>
          <Button
            style={{ margin: "auto", display: "grid", color: "#000" }}
            value={
              isLoading ? (
                <PreLoader />
              ) : (
                `Sign up ${
                  notificationTimeoutStyles() === "pending"
                    ? "(check your email)"
                    : ""
                }`
              )
            }
            buttonStyle={notificationTimeoutStyles()}
            type={"submit"}
          />
        </form>
      </div>
      <p
        style={{
          fontWeight: "lighter",
          fontStyle: "italic",
          textAlign: "center",
        }}
      >
        T&amp;Cs Apply
      </p>
    </div>
  );
}

export default Form;
