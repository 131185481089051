import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CloseOutlined } from "@material-ui/icons";
import Context from "../Context";
import { Avatar, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import Button from "../UI/Form/Button/Button";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: "60%",
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

const Search = React.memo(() => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const formSchema = {
    searchTerm: "",
    SearchType: "",
  };

  const [formFields, setFormFields] = useState(formSchema);
  const [featuredWriters, setFeaturedWriters] = useState([]);
  const [formError, setFormError] = useState(formSchema);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    isSearchOn,
    setIsSearchOn,
    handleRemoveChangeSIcon,
    LINKURL,
    APIURL,
  } = useContext(Context);

  function handleSubmit(e) {
    const URLData =
      "/wp/?rest_route=/wp/v2/featured_writers&per_page=14&_embed";
    e.preventDefault();
    if (formFields.SearchType === "Writers") {
      axios
        .get(`${APIURL}${URLData}`)
        .then(setIsLoaded(true))
        .then((res) => {
          setFeaturedWriters(res.data);
          setIsLoaded(false);
        })
        .catch((err) => {
          setIsLoaded(false);
        });
    }
    if (formFields.SearchType === "Language") {
      axios
        .get(`${APIURL}${URLData}`)
        .then(setIsLoaded(true))
        .then((res) => {
          setFeaturedWriters(res.data);
          setIsLoaded(false);
        })
        .catch((err) => {
          setIsLoaded(false);
        });
    }
    if (formFields.SearchType === "Poems") {
      axios
        .get(`${APIURL}${URLData}`)
        .then(setIsLoaded(true))
        .then((res) => {
          setFeaturedWriters(res.data);
          setIsLoaded(false);
        })
        .catch((err) => {
          setIsLoaded(false);
        });
    }
    if (formFields.SearchType === "") {
      setFormError({ ...formError, SearchType: true });
    } else if (formFields.SearchTerm === "") {
      setFormError({ ...formError, SearchTerm: true });
    }
    return null;
  }

  const featuredWritersMap = featuredWriters.map((params) => {
    const { title, _embedded, excerpt, id, categories, acf } = params;

    return { title, _embedded, excerpt, id, categories, acf };
  });

  function SearchFilter(params) {
    const cat = [
      "", //0
      "afrikaans", //1
      "", //2
      "xhosa", //3
      "english", //4
      "", //5
      "sesotho", //6
      "ndebele", //7
      "swazi", //8
      "zulu", //9
      "xitsonga", //10
      "sepedi", //11
      "tswana", //12
      "venda", //13
    ];

    const catMap = cat.filter((category, index) => {
      if (index === params.categories[1]) {
        return category;
      }
      return null;
    });

    if (
      formFields.searchTerm === "Select Search Category" ||
      formFields.searchTerm === ""
    )
      return;
    if (formFields.SearchType === "Writers") {
      if (
        params.title.rendered
          .toLowerCase()
          .includes(formFields.searchTerm.toLowerCase())
      ) {
        return params;
      }
    }
    if (formFields.SearchType === "Language") {
      if (catMap[0]?.includes(formFields.searchTerm.toLowerCase())) {
        return params;
      }
    }
    if (formFields.SearchType === "Poems") {
      const matchSearch = [params.acf.poem_title, params.acf.poem_two_title];
      const matchSearchMap = matchSearch.filter((val) => {
        if (
          formFields.searchTerm
            .toLowerCase()
            .includes(params.acf.poem_title.toLowerCase())
        ) {
          return val.toLowerCase();
        }
        if (
          formFields.searchTerm
            .toLowerCase()
            .includes(params.acf.poem_two_title.toLowerCase())
        ) {
          return val.toLowerCase();
        }
        return null;
      });
      if (
        matchSearchMap[0]
          ?.toLowerCase()
          .includes(formFields.searchTerm.toLowerCase())
      ) {
        return params;
      }
      if (
        matchSearchMap[1]
          ?.toLowerCase()
          .includes(formFields.searchTerm.toLowerCase())
      ) {
        return params;
      }
      return null;
    }
    return null;
  }

  const SearchResults = featuredWritersMap.filter(SearchFilter);

  const MapSearchResults = SearchResults.map((params) => {
    const imgURL =
      params._embedded["wp:featuredmedia"][0].media_details.sizes.thumbnail
        .source_url;

    function checkImgURL() {
      if (imgURL === null || undefined) {
        return "";
      } else {
        return (
          <img
            key={params.id}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={imgURL}
            alt={imgURL}
          />
        );
      }
    }
    return (
      <Paper key={params.id} className={classes.paper}>
        <Grid container wrap="nowrap" spacing={2}>
          {formFields.SearchType === "Poems" ? null : (
            <Grid item>
              <Avatar>{checkImgURL()}</Avatar>
            </Grid>
          )}

          <Grid item xs>
            <Typography gutterBottom variant="h5" component="h2">
              {formFields.SearchType === "Poems"
                ? params.acf.poem_title
                : params.title.rendered}
            </Typography>
            <div
              className={"MuiTypography-root MuiTypography-body1 pageP"}
              dangerouslySetInnerHTML={{ __html: params.excerpt.rendered }}
            />
            <Link
              to={{
                pathname: `${LINKURL}/The-national-Choreopoem/writer/${params.title.rendered}`,
                state: { prevPath: pathname },
              }}
            >
              <Button
                onClick={(e) => {
                  setIsSearchOn(false);
                }}
                style={{ marginTop: "20px" }}
                value={"Read more"}
              />
            </Link>
          </Grid>
        </Grid>
      </Paper>
    );
  });

  const SearchResultLog = (params) => {
    if (featuredWriters.length === 0) {
      return (
        <Paper key={params.id} className={classes.paper}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="h4" component="h2">
                your search results will appear here
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      );
    } else if (featuredWriters.length > 0) {
      return (
        <Paper key={params.id} className={classes.paper}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="h4" component="h2">
                No Search Results Found
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      );
    }
  };

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "50%" }}
      >
        <span style={{ backgroundColor: "#fff" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#fff" }} />
      </div>
    );
  }
  const [domReady, setDomReady] = React.useState(false)

  useEffect(() => {
    setDomReady(true)
  },[])

  return domReady? ReactDOM.createPortal(
    isSearchOn ? (
      <>
        <div onClick={handleRemoveChangeSIcon} className={"Modal--Close"}>
          <CloseOutlined
            onClick={() => {
              setFeaturedWriters([]);
            }}
            color={"inherit"}
            fontSize={"large"}
          />
        </div>
        <div
          className={"Search--Header  animate__animated animate__fadeInDown"}
        >
          <div className={"Search--Header--Wrapper"}>
            <form onSubmit={handleSubmit}>
              <select
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    SearchType: e.target.value,
                  });
                }}
              >
                <option>Select Search Category</option>
                <option>Writers</option>
                <option>Language</option>
                <option>Poems</option>
              </select>
              <input
                name={"search"}
                type={"text"}
                placeholder={"Search & Enter"}
                onChange={(e) => {
                  setFormFields({ ...formFields, searchTerm: e.target.value });
                }}
                value={formFields.id}
              />
              <button
                type={"submit"}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "50px",
                  color: "#000",
                }}
              >
                Search
              </button>
            </form>
          </div>
        </div>
        <div className={"Search--Wrapper animate__animated animate__fadeIn"}>
          {isLoaded ? (
            <PreLoader />
          ) : (
            <div className={"Content--Wrapper"}>
              {MapSearchResults.length === 0 ? (
                <SearchResultLog />
              ) : (
                MapSearchResults
              )}
            </div>
          )}
        </div>
      </>
    ) : null,
    document.getElementById("modal")
  ):null;
});

export default Search;
