import { List, ListItem, ListItemText } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Context from "../Context";
import NavItemsContext from "./NavItemsContext";

export default function NavItemsContextProvider(props) {
  const { navItems, LINKURL, CheckDeviceWidth } = useContext(Context);

  const [subMenu, setSubMenu] = useState(false);
  const [subMenu2, setSubMenu2] = useState(false);

  function showSubMenu() {
    setSubMenu(true);
  }

  function closeSubMenu() {
    setSubMenu(false);
  }
  function showSubMenu2() {
    setSubMenu2(true);
  }

  function closeSubMenu2() {
    setSubMenu2(false);
  }

  const NavItemsMap = navItems.map(function (params) {
    const { object_id, title, ID } = params;

    const NavItemsSubmenuMap = navItems.map(function (params) {
      const { post_parent, title, ID } = params;
      const LCTitle = title.toLowerCase();
      if (post_parent === 2) {
        if (CheckDeviceWidth) {
          return (
            <Link key={ID} to={`${LINKURL}/The-national-Choreopoem/${LCTitle}`}>
              <ListItem button>
                <ListItemText
                  primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                />
              </ListItem>
            </Link>
          );
        } else {
          return (
            <li key={ID}>
              <Link to={`${LINKURL}/The-national-Choreopoem/${LCTitle}`}>
                {title.replace(/([#-;]+)/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        }
      }
      return null;
    });

    const NavItemsSubmenu3Map = navItems.map(function (params) {
      const { post_parent, title, ID } = params;

      if (post_parent === 10) {
        if (CheckDeviceWidth) {
          return (
            <Link
              key={ID}
              to={`${LINKURL}/blog/outreach/${title.toLowerCase()}`}
            >
              <ListItem button>
                <ListItemText
                  primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                />
              </ListItem>
            </Link>
          );
        } else {
          return (
            <li key={ID}>
              <Link
                to={`${LINKURL}/blog/outreach/${title.toLowerCase()}`}
              >
                {title.replace(/([#-;]+)/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        }
      }
      return null;
    });

    const NavItemsSubmenu4Map = navItems.map(function (params) {
      const { post_parent, title, ID } = params;

      if (post_parent === 588) {
        if (CheckDeviceWidth) {
          return (
            <Link
              key={ID}
              to={`${LINKURL}/blog/outreach/${title.toLowerCase()}`}
            >
              <ListItem button>
                <ListItemText
                  primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                />
              </ListItem>
            </Link>
          );
        } else {
          return (
            <li key={ID}>
              <Link
                to={`${LINKURL}/blog/${title.toLowerCase()}`}
              >
                {title.replace(/([#-;]+)/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        }
      }
      return null;
    });

    const NavItemsSubmenu5Map = navItems.map(function (params) {
      const { post_parent, title, ID } = params;

      if (post_parent === 278) {
        if (CheckDeviceWidth) {
          return (
            <Link
              key={ID}
              to={`${LINKURL}/now/shopping`}
            >
              <ListItem button>
                <ListItemText
                  primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                />
              </ListItem>
            </Link>
          );
        } else {
          return (
            <li key={ID}>
              <Link
                to={`${LINKURL}/now/shopping`}
              >
                {title.replace(/([#-;]+)/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        }
      }
      return null;
    });

    const NavItemsSubmenu6Map = navItems.map(function (params) {
      const { post_parent, title, ID } = params;

      if (post_parent === 495) {
        if (CheckDeviceWidth) {
          return (
            <Link
              key={ID}
              to={`${LINKURL}/illustration-artist/${title}`}
            >
              <ListItem button>
                <ListItemText
                  primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                />
              </ListItem>
            </Link>
          );
        } else {
          return (
            <li key={ID}>
              <Link
                to={`${LINKURL}/illustration-artist/${title}`}
              >
                {title.replace(/([#-;]+)/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        }
      }
      return null;
    });

    const NavItemsSubmenu7Map = navItems.map(function (params) {
      const { post_parent, title, ID } = params;

      if (post_parent === 2) {
        if (CheckDeviceWidth) {
          return (
            <Link
              key={ID}
              to={`${LINKURL}/translated-books/${title}`}
            >
              <ListItem button>
                <ListItemText
                  primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                />
              </ListItem>
            </Link>
          );
        } else {
          return (
            <li key={ID}>
              <Link
                to={`${LINKURL}/translated-books/${title}`}
              >
                {title.replace(/([#-;]+)/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        }
      }
      return null;
    });

    const NavItemsSubmenu2Map = navItems.map(function (params) {
      const { post_parent, title, ID } = params;
      const LCTitle = title.toLowerCase();
      if (post_parent === 7) {
        if (CheckDeviceWidth) {
          return (
            <Link key={ID} to={`${LINKURL}/women&children/${LCTitle}`}>
              <ListItem button>
                <ListItemText
                  primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                />
              </ListItem>
            </Link>
          );
        } else {
          return (
            <li key={ID}>
              <Link to={`${LINKURL}/women&children/${LCTitle}`}>
                {title.replace(/([#-;]+)/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        }
      }
      return null;
    });

    const NavItemsMap2 = navItems.map(function (params) {
      const { object_id, title, ID } = params;
      if (object_id === `${LINKURL}/`) {
        if (CheckDeviceWidth) {
          return (
            <ListItem button key={ID}>
              <List className="Sub--Menu">{NavItemsSubmenu2Map}</List>
            </ListItem>
          );
        } else {
          return (
            <li key={ID}>
              {title.replace(/([#-;]+)/g, "&")}
              <ul className="Sub--Menu">{NavItemsSubmenu2Map}</ul>
            </li>
          );
        }
      }
      return null;
    });

    const NavItemsParenNCMap = navItems.map(function (params) {
      const { post_parent, title, ID, menu_order } = params;
      const LCTitle = title.toLowerCase();
      
      if (
        post_parent === 0 &&
        menu_order !== 1 &&
        menu_order !== 13 &&
        menu_order !== 14 &&
        menu_order !== 16 &&
        menu_order !== 19 &&
        menu_order !== 17 &&
        menu_order !== 18 &&
        menu_order !== 20 &&
        menu_order !== 21 &&
        menu_order !== 22
      ) {
        if (CheckDeviceWidth) {
          if (ID === 57) {
            return (
              <ListItem button key={ID}>
                <Link to={`${LINKURL}/blog/${LCTitle}`}>
                  <ListItemText
                    primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                  />
                </Link>
              </ListItem>
            );
          } else {
            return (
              <ListItem button key={ID}>
                <Link to={`${LINKURL}/home/${LCTitle}`}>
                  <ListItemText
                    primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                  />
                </Link>
              </ListItem>
            );
          }
        } else if (ID === 58) {
          return (
            // Contact Nav item
            <li key={ID}>
              <Link to={`${LINKURL}/${LCTitle}`}>
                {title.replace(/([#-;]+)/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        } else {
          return (
            <li key={ID}>
              <Link to={`${LINKURL}/blog/${LCTitle}`}>
                {title.replace(/([#-;]+)/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        }
      } else if (ID === 58) {
        if (CheckDeviceWidth) {
          return (
            <ListItem button key={ID}>
              <Link to={`${LINKURL}/${LCTitle}`}>
                <ListItemText
                  primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                />
              </Link>
            </ListItem>
          );
        } else {
          return (
            <li key={ID}>
              <Link to={`${LINKURL}/${LCTitle}`}>
                {title.replace(/([#-;]+)/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        }
      }
      return null;
    });

    const NavItemsParenNCMapArtist = navItems.map(function (params) {
      const { title, ID, menu_order } = params;
      const LCTitle = title.toLowerCase();
      if (menu_order === 21) {
        if (CheckDeviceWidth) {
          return (
            <ListItem button key={ID}>
              <Link to={`${LINKURL}/illustration-artist/${LCTitle}`}>
                <ListItemText
                  primary={title.replace(/([#-;]+)/g, " ").toUpperCase()}
                />
              </Link>
            </ListItem>
          );
        } else {
          return (
            <li key={ID}>
            {title.replace(/([#-;]+)/g, "&").toUpperCase()}
              <ul className="Sub--Menu">{NavItemsSubmenu6Map}</ul>
            </li>
          );
        }
      }
      return null;
    });

    if (object_id === "2") {
      if (CheckDeviceWidth) {
        return (
          <div key={ID}>
            <Link to={`${LINKURL}/`}>
              <ListItem button>HOME</ListItem>
            </Link>
            {subMenu ? (
              <ListItem
                onClick={closeSubMenu}
                button
                className={"has--sub--menu"}
              >
                {title.replace(/([#-;]+)/g, "&")}
              </ListItem>
            ) : (
              <ListItem
                onClick={showSubMenu}
                button
                className={"has--sub--menu"}
              >
                {title.replace(/([#-;]+)/g, "&")}
              </ListItem>
            )}
            {subMenu ? (
              <List className="Sub--Menu">{NavItemsSubmenuMap}</List>
            ) : null}

            {NavItemsMap2}
            {NavItemsParenNCMapArtist}

            {subMenu2 ? (
              <ListItem
                onClick={closeSubMenu2}
                button
                className={"has--sub--menu"}
              >
                Outreach Programmes 
              </ListItem>
            ) : (
              <ListItem
                onClick={showSubMenu2}
                button
                className={"has--sub--menu"}
              >
                {"Outreach Programmes".toUpperCase()}
              </ListItem>
            )}
            {subMenu2 ? (
              <List className="Sub--Menu">{NavItemsSubmenu3Map}</List>
            ) : null}
            {subMenu2 ? (
              <List className="Sub--Menu">{NavItemsSubmenu4Map}</List>
            ) : null}

            {NavItemsParenNCMap}
          </div>
        );
      } else {
          return(
            
            <div key={ID}>
            {/* <li>
              <Link to={`${LINKURL}/user/signin`}>Signin</Link>
            </li> */}

            <li>
              <Link to={`${LINKURL}/`}>Home</Link>
            </li>

            <li>
              <Link to={`${LINKURL}/about/our-story`}>Our Story</Link>
            </li>

            <li>
              {title.replace(/([#-;]+)/g, "&")}
              <ul className="Sub--Menu">{NavItemsSubmenuMap}</ul>
            </li>
            {NavItemsParenNCMapArtist}
            <li>
            Translated Books
              <ul className="Sub--Menu">{NavItemsSubmenu7Map}</ul>
            </li>
            {NavItemsMap2}
            <li>
            Outreach Programmes 
              <ul className="Sub--Menu">{NavItemsSubmenu3Map}</ul>
            </li>
            <li>
            Practice of Healing
              <ul className="Sub--Menu">{NavItemsSubmenu4Map}</ul>
            </li>
            <li>
            Store
              <ul className="Sub--Menu">{NavItemsSubmenu5Map}</ul>
            </li>
            {NavItemsParenNCMap}
          </div>
          )
        }
      }
    
    return null;
  });

  return (
    <NavItemsContext.Provider value={{ NavItemsMap }}>
      {props.children}
    </NavItemsContext.Provider>
  );
}
