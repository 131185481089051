import React, { useContext, useEffect } from "react";
import { CloseOutlined } from "@material-ui/icons";
import ReactDOM, { createPortal } from "react-dom";
import Context from "../Context";

function ImageViewerModal() {
  const { setimageModalOn, imageToView, setimageToView, imageToViewArray } =
    useContext(Context);
    const [domReady, setDomReady] = React.useState(false)
  
    useEffect(() => {
      setDomReady(true)
    },[])

  return createPortal(
    <div>
      <div
        onClick={() => {
          setimageToView();
          setimageModalOn(false);
        }}
        className={"Modal--Close"}
      >
        <CloseOutlined
          onClick={() => {
            return null;
          }}
          color={"inherit"}
          fontSize={"large"}
        />
      </div>
      <div
        className={"Search--Wrapper animate__animated animate__fadeIn"}
        style={{ overflow: "hidden" }}
      >
        <div
          style={{
            position: "relative",
            width: "95%",
            height: "100%",
            margin: "auto",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "50%",
              height: "auto",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-60%)",
              transformOrigin: "center",
            }}
            className="imageToView"
          >
            <img
              src={imageToView}
              alt={""}
              style={{
                width: "100%",
                height: "auto",
                filter: "drop-shadow(0px 0px 46px black)",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "50px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "max-content",
              height: "auto",
              display: "flex",
              gap: "5px",
            }}
          >
            {imageToViewArray?.map((item, i) => {
              const match = item?.currentSrc === imageToView;

              return (
                <div
                  key={i}
                  style={{
                    width: "100px",
                    height: "auto",
                    // border: "2px solid red",
                    filter: match ? "drop-shadow(0px 0px 5px black)" : "none",
                  }}
                  className="imageToViewThumbnail"
                >
                  <img
                    onClick={() => {
                      setimageToView(item?.currentSrc);
                    }}
                    src={item?.currentSrc}
                    alt={""}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      cursor: "pointer",
                      border: match ? "2px solid #ffc107" : "none",
                      boxSizing: "border-box",
                      filter: match ? "grayscale(0)" : "grayscale(1)",
                      opacity: match ? 1 : 0.8,
                      scale: match ? 1 : 0.8,
                    }}
                  />
                </div>
              );
            })}
            {/* <div style={{ display: "flex", gap: "5px" }}>
              <button>Prev</button>
              <button>next</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal")
  );
}

export default ImageViewerModal;
