import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import SearchContextProvider from "./components/ContextProvider";
import PlayerContextProvider from "./components/Context/PlayerContextProvider";

ReactDOM.render(
  <React.StrictMode>
    <SearchContextProvider>
      <PlayerContextProvider>
        <App />
      </PlayerContextProvider>
    </SearchContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
