import React, { useState } from "react";
import PlayerContext from "./PlayerContext";

export default function PlayerContextProvider(props) {
  const [isAudioPlaying, setisAudioPlaying] = useState(false);

  const [AudioSource, setAudioSource] = useState();

  const [isPlaying, setisPlaying] = useState(true);
  const [isPlayingTrigger, setisPlayingTrigger] = useState(false);

  const AudioContext = window.AudioContext || window.webkitAudioContext;

  const audioContext = new AudioContext();

  const analyser = audioContext.createAnalyser();

  return (
    <PlayerContext.Provider
      value={{
        AudioContext,
        audioContext,
        setisPlaying,
        isPlaying,
        isAudioPlaying,
        AudioSource,
        setAudioSource,
        setisAudioPlaying,
        analyser,
        setisPlayingTrigger,
        isPlayingTrigger,
      }}
    >
      {props.children}
    </PlayerContext.Provider>
  );
}
