import { Avatar, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Context from "../components/Context";
import Button from "../components/UI/Form/Button/Button";
import FeaturedWritters from "../section/featuredWriters/FeaturedWritters";
import Footer from "../section/footer/Footer";
import SocialMedia from "../section/socialMedia/SocialMedia";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

function GenericPost2() {
  const classes = useStyles();
  const {
    pages,
    isLoading,
    OutReach,
    LINKURL,
    CheckDeviceWidth,
    AnimeCSS,
    coverArtist,
    translatedBooks,
  } = useContext(Context);
  
  const { pathname } = useLocation();

  const { x, d } = useParams();

  const pagesMap = pages.map(function (pagesMapData) {
    const { title, content, id } = pagesMapData;
    const titleFormat = title.rendered.toLowerCase();
    if (x === titleFormat || d === titleFormat) {
      function SponsorContact() {
        if (x === "Sponsors") {
          return (
            <a
              href={"https://www.nac.org.za/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                style={{
                  width: "fit-content",
                  margin: "auto",
                  display: "flex",
                }}
                value={"www.nac.org.za"}
              />
            </a>
          );
        }
        return null;
      }
      return (
        <div key={id} className={"Content--Wrapper"}>
          <div className={"Our--Story--Content"}>
            {/* <h1>{title.rendered}</h1> */}
            <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
            {SponsorContact()}
          </div>
        </div>
      );
    }
    return null;
  });

  const pagesImageMap = pages.map(function (pagesMapData) {
    const { title, _embedded, id } = pagesMapData;
    const titleFormat = title.rendered.toLowerCase();

    const HeaderImage = () => {
      if (CheckDeviceWidth) {
        if (_embedded["wp:featuredmedia"] === undefined || null) {
          return null;
        } else {
          return (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={_embedded["wp:featuredmedia"][0].source_url}
              alt={""}
            />
          );
        }
      } else if (_embedded["wp:featuredmedia"] === undefined || null) {
        return null;
      } else {
        return _embedded["wp:featuredmedia"][0].source_url;
      }
    };
    
    if (x === titleFormat || pathname.split("/")[1]?.replace(/([#-;]+)/g, " ") === titleFormat || d === titleFormat) {
      function checkIfImageExist() {
        if (_embedded["wp:featuredmedia"] === undefined || null) {
          return (
            <div
              style={{
                height: "30vh",
                position: "relative",
                backgroundAttachment: "fixed",
                backgroundPosition: "center center",
                filter: "grayscale(1)",
                backgroundSize: "cover",
                backgroundImage: `url(${HeaderImage()})`,
              }}
            >
              {_embedded["wp:featuredmedia"] === undefined || null ? (
                ""
              ) : (
                <HeaderImage />
              )}

              <div
                style={{ position: "absolute", top: "0", left: "0" }}
                className={"BG--OL2"}
              />
              <h1
                style={{
                  position: "absolute",
                  display: "block",
                  bottom: "-11px",
                  fontSize: "2.5rem",
                  left: "50%",
                  transform: "translateX(-50%)",
                  margin: "auto",
                  color: "#fff",
                  textTransform: "uppercase",
                  width: "fit-content",
                }}
              >
                {title.rendered}
              </h1>
            </div>
          );
        } else {
          return (
            <div
              style={{
                height: "30vh",
                position: "relative",
                backgroundAttachment: "fixed",
                backgroundPosition: "center center",
                filter: "grayscale(1)",
                backgroundSize: "cover",
              }}
            >
              {_embedded["wp:featuredmedia"] === undefined || null ? (
                ""
              ) : (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={_embedded["wp:featuredmedia"][0].source_url}
                  alt={""}
                />
              )}
              <div
                style={{ position: "absolute", top: "0", left: "0" }}
                className={"BG--OL"}
              />
              <h1
                style={{
                  position: "absolute",
                  display: "block",
                  bottom: "-11px",
                  fontSize: "2.5rem",
                  left: "50%",
                  transform: "translateX(-50%)",
                  margin: "auto",
                  color: "#fff",
                  textTransform: "uppercase",
                  width: "fit-content",
                }}
              >
                {title.rendered}
              </h1>
            </div>
          );
        }
      }

      return (
        <div key={id} style={{ height: "100%" }} className={""}>
          {checkIfImageExist()}
        </div>
      );
    }

    return null;
  });

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#fff" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#fff" }} />
      </div>
    );
  }

  const gridMap = OutReach.map((OutReachData) => {
    const { title, id, _embedded } = OutReachData;

    return (
      <div key={id}>
        <Link to={`/blog/outreach/${title.rendered}`}>
          <img
            src={
              _embedded["wp:featuredmedia"] === undefined
                ? null
                : _embedded["wp:featuredmedia"][0].source_url
            }
            alt={""}
          />
          <div className="BG--OL">
            <h1>{title.rendered}</h1>
          </div>
        </Link>
      </div>
    );
  });

  const grid = () => {
    if (pathname === `${LINKURL}/home/outreach`) {
      return <div className={"gridLayout"}>{gridMap}</div>;
    } else {
      return isLoading ? <PreLoader /> : pagesMap;
    }
  };

  const coverArtistData = coverArtist.map((params) => {
    const { title, id, _embedded, excerpt } = params;
    return (
      <Paper key={id} className={classes.paper}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar>
              <img
                style={{ width: "100%", height: "100%" }}
                src={
                  _embedded["wp:featuredmedia"]?.[0].media_details.sizes
                    .thumbnail.source_url
                }
                alt=""
              />
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography gutterBottom variant="h5" component="h2">
              {title.rendered}
            </Typography>
            <div
              className={"MuiTypography-root MuiTypography-body1 pageP"}
              dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
            />
            <Link
              to={{
                pathname: `${LINKURL}/illustration-artist/single/${title.rendered}`,
                state: { prevPath: pathname },
              }}
            >
              <Button style={{ marginTop: "20px" }} value={"Read more"} />
            </Link>
          </Grid>
        </Grid>
      </Paper>
    );
  });

  const translatedBooksData = translatedBooks.map((params) => {
    const { title, id, _embedded, excerpt,slug } = params;
    if(x?.toLowerCase() === slug){
      return (
            <Paper key={id} className={classes.paper}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={
                        _embedded["wp:featuredmedia"]?.[0].media_details.sizes
                          .thumbnail.source_url
                      }
                      alt=""
                    />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography gutterBottom variant="h5" component="h2">
                    {title.rendered}
                  </Typography>
                  <div
                    className={"MuiTypography-root MuiTypography-body1 pageP"}
                    dangerouslySetInnerHTML={{ __html: excerpt?.rendered }}
                  />
                  <Link
                    to={{
                      pathname: `${LINKURL}/translated-books/single/${title.rendered}`,
                      state: { prevPath: pathname },
                    }}
                  >
                    <Button style={{ marginTop: "20px" }} value={"Read more"} />
                  </Link>
                </Grid>
              </Grid>
            </Paper>
          );
    } else {
      return (
        null
      )
    }
    
  });

console.log("d",d)

  return (
    <div className={AnimeCSS()}>
      <div style={{ height: "30vh" }} className={"Main--Banner--Wrapper"}>
        {isLoading ? <PreLoader /> : pagesImageMap}
      </div>
      {d === pathname.split("/")[2] ? (
        <div className={"Content--Wrapper Help--Line"}>{coverArtistData}</div>
      ) : (
        <div className={"grid--Wrapper"}>{grid()}</div>
      )}
      
      {x === pathname.split("/")[2] ? (
        <div className={"Content--Wrapper Help--Line"}>{translatedBooksData}</div>
      ) : (
        <div className={"grid--Wrapper"}>{grid()}</div>
      )}

      {pathname !== "/home/heal" ? (
        <>
          <FeaturedWritters />
          <SocialMedia />
        </>
      ) : null}

      <Footer />
    </div>
  );
}

export default GenericPost2;
