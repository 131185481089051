import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Context from "../components/Context";
import ContactUs from "../section/contactUs/ContactUs";
import Footer from "../section/footer/Footer";
import SocialMedia from "../section/socialMedia/SocialMedia";

function ContactPage() {
  const { pages, isLoaded, CheckDeviceWidth, AnimeCSS } = useContext(Context);

  let { d } = useParams();
  const matchLink = pages.map(function (params) {
    const { slug, title, _embedded, id } = params;
    const ad = slug.replace(/([#-;]+)/g, " ");

    const checkMatch = d === ad;

    const HeaderImage = () => {
      if (CheckDeviceWidth) {
        if (_embedded["wp:featuredmedia"] === undefined || null) {
          return null;
        } else {
          return (
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={_embedded["wp:featuredmedia"][0].source_url}
              alt={""}
            />
          );
        }
      } else if (_embedded["wp:featuredmedia"] === undefined || null) {
        return null;
      } else {
        return _embedded["wp:featuredmedia"][0].source_url;
      }
    };
    if (checkMatch) {
      return (
        <div key={id} style={{ height: "100%" }} className={""}>
          <div
            style={{
              height: "30vh",
              position: "relative",
              backgroundAttachment: "fixed",
              backgroundPosition: "center center",
              filter: "grayscale(1)",
              backgroundSize: "cover",
              backgroundImage: `url(${HeaderImage()})`,
            }}
          >
            {/* {_embedded["wp:featuredmedia"] === undefined || null ? (
              ""
            ) : (
              <HeaderImage />
            )} */}
            <div
              style={{ position: "absolute", top: "0", left: "0" }}
              className={"BG--OL2"}
            />
            <h1
              style={{
                position: "absolute",
                display: "block",
                bottom: "-25px",
                fontSize: "5.8rem",
                left: "50%",
                transform: "translateX(-50%)",
                margin: "auto",
                color: "#fff",
                textTransform: "uppercase",
              }}
            >
              {title.rendered}
            </h1>
          </div>
        </div>
      );
    }
    return null;
  });

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#181818" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#181818" }} />
      </div>
    );
  }

  return (
    <div className={AnimeCSS()}>
      <div style={{ height: "30vh" }} className={"Main--Banner--Wrapper"}>
        {isLoaded ? <PreLoader /> : matchLink}
      </div>
      <ContactUs />
      <SocialMedia />
      <Footer />
    </div>
  );
}

export default ContactPage;
