import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Context from "../../components/Context";
import NavItemsContext from "../../components/Context/NavItemsContext";
// import UserLoggedin from "../../components/UI/UserLoggedin";

function Nav() {
  const { isLoaded, LINKURL } = useContext(Context);
  const { NavItemsMap } = useContext(NavItemsContext);
  
  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#181818" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#181818" }} />
      </div>
    );
  }

  const DesktopNav = () => {
    return (
      <nav className="Content--Wrapper">
        <div className="Nav--Class--Wrapper">
          <div className="Bran-d--Logo">
            <Link to={`${LINKURL}/`} style={{ display: "flex" }}>
              <img src={`${LINKURL}/images/Loss-iLahleko-Logo-A.png`} alt="" />
            </Link>
          </div>
          <div className="Nav--Items">
            <ul>{isLoaded ? <PreLoader LINKURL={LINKURL} /> : NavItemsMap}</ul>
            {/* <UserLoggedin /> */}
          </div>
        </div>
      </nav>
    );
  };

  return <DesktopNav />;
}

export default Nav;
