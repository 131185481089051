import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@material-ui/icons";
import React, { useContext } from "react";
import Slider from "react-slick";
import Context from "../../components/Context";

function Statistics() {
  const { stats } = useContext(Context);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const statsData = stats.map((statsDataMap) => {
    const { title, content, id } = statsDataMap;

    return (
      <div
        key={id}
        className={"Statistics--Slider"}
        tabIndex="-1"
        style={{ width: "100%", display: "inline-block" }}
      >
        <div className={"Statistics--Slider--Wrapper"}>
          {/* <img
            src={_embedded["wp:featuredmedia"][0].source_url}
            className={"Statistics--Slider--Icon"}
            alt={""}
          /> */}
          <div className={"Statistics--Slider--Content--Wrapper"}>
            <h1>{title.rendered}</h1>
            <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
          </div>
        </div>
      </div>
    );
  });

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`Statistics--Slider--nav Next ${className}`}
        style={{ ...style, left: "unset", right: "0" }}
        onClick={onClick}
      >
        <ArrowForwardIosOutlined color={"inherit"} fontSize={"large"} />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`Statistics--Slider--nav Prev  ${className}`}
        style={{ ...style }}
        onClick={onClick}
      >
        <ArrowBackIosOutlined color={"inherit"} fontSize={"large"} />
      </div>
    );
  }

  const StatsCheckDeviceWidth = () => {
    if (window.innerWidth <= 600) {
      return null;
    } else {
      return (
        <div
          style={{
            background:
              "#d4b095",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div className={"Content--Wrapper Content--Wrapper--stats stats"}>
            <Slider {...settings}>{statsData.reverse()}</Slider>
          </div>
        </div>
      );
    }
  };

  return <StatsCheckDeviceWidth />;
}

export default Statistics;
