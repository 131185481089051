import {
  PauseCircleFilledOutlined,
  PlayCircleFilledOutlined,
} from "@material-ui/icons";
import React, { useContext, useRef, useState } from "react";
import PlayerContext from "../Context/PlayerContext";
import PlayerContextProvider from "../Context/PlayerContextProvider";
import Button from "../UI/Form/Button/Button";

function Player({ audioSRC, i, acf }) {
  const { AudioContext, audioContext, setisPlaying, analyser } =
    useContext(PlayerContext);

  const [isAudioPlaying, setisAudioPlaying] = useState(true);
  const [trackedPlayedSongs, settrackedPlayedSongs] = useState([]);
  //   Refs
  const canvasRef = useRef([]);
  const audioRef = useRef([]);
  audioRef.current = [];
  canvasRef.current = [];

  let indexToString = i.toString();
  let indexString = i;

  const storedPlayedSongs = trackedPlayedSongs.filter((i) => i === indexString);

  const AddSongIndex = () => {
    if (!storedPlayedSongs.includes(i)) {
      settrackedPlayedSongs([...trackedPlayedSongs, i]);
    }
  };

  const AudioFunc = () => {
    const canvas = canvasRef.current[0];
    const ctx = canvas.getContext("2d");

    const audioElement = audioRef.current[0];

    const track = audioContext.createMediaElementSource(audioElement);

    track.connect(analyser);
    analyser.connect(audioContext.destination);
    var fbc_array, bars, bar_x, bar_width, bar_height;
    const frameLooper = () => {
      window.requestAnimationFrame(frameLooper);
      fbc_array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(fbc_array);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "#898989";
      bars = 20;
      for (var i = 0; i < bars; i++) {
        bar_x = i * 10;
        bar_width = 20;
        bar_height = -(fbc_array[i] / 6);
        ctx.fillRect(bar_x, canvas.height, bar_width, bar_height);
      }
    };

    return frameLooper();
  };

  function HandlePlay() {
    audioRef.current[0].play();
    setisAudioPlaying([{ i }]);
    if (indexToString === canvasRef.current[0].dataset.id) {
      AddSongIndex();

      if (storedPlayedSongs.includes(i)) return;

      if (!storedPlayedSongs.includes(i)) {
        AudioFunc();
      }
    }
  }

  function HandlePause() {
    audioRef.current[0].pause();
    setisPlaying(false);
    setisAudioPlaying([!{ i }]);
  }

  const AddtoRefs = (el) => {
    if (el && !audioRef.current.includes(el)) {
      audioRef.current.push(el);
    }
  };

  const AddtoCanvasRefs = (el) => {
    if (el && !canvasRef.current.includes(el)) {
      canvasRef.current.push(el);
    }
  };

  const contentType = () => {
    if (acf?.select_content_type?.toLowerCase() === "poetry") {
      return <span>Poem</span>;
    } else {
      return <span>Story</span>;
    }
  };

  return (
    <>
      <PlayerContextProvider audioSRC={audioSRC} i={i}>
        {AudioContext === undefined ? null : (
          <audio
            ref={AddtoRefs}
            src={audioSRC || ""}
            crossOrigin="anonymous"
            type="audio/mp3"
          />
        )}
        {isAudioPlaying[0]?.i === i ? (
          <Button onClick={HandlePause} style={{ backgroundColor: "#d4b095", color:"#fff" }}>
            Pause {contentType()}
            <span className={"Main--Banner--Artist--Play--Icon"}>
              <PauseCircleFilledOutlined />
            </span>
          </Button>
        ) : (
          <Button onClick={HandlePlay} style={{ backgroundColor: "#d4b095",color:"#fff" }}>
            Play {contentType()}
            <span className={"Main--Banner--Artist--Play--Icon"}>
              <PlayCircleFilledOutlined />
            </span>
          </Button>
        )}
        <canvas data-id={i} ref={AddtoCanvasRefs} id="analyser_render" />
      </PlayerContextProvider>
    </>
  );
}

export default Player;
