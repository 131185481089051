import React, { useContext } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "./App.css";
import "./AppMQ.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./routes/HomePage";
import GenericPost from "./routes/GenericPost";
import Header from "./section/header/Header";
import PageSingle from "./routes/PageSingle";
import GenericPostArtist from "./routes/GenericPostArtist";
import ContactPage from "./routes/ContactPage";
import ScrollToTop from "./components/ScrollToTop";
import BackToTopBTN from "./components/BackToTopBTN";
import GenericPost2 from "./routes/GenericPost2";
import NavItemsContextProvider from "./components/Context/NavItemsContextProvider";
import PoemsPage from "./routes/PoemsPage";
import Shop from "./routes/Shop";
import Signin from "./routes/Registration/Signin";
import ArtistAll from "./routes/ArtistAll";
import BlogSingle from "./routes/BlogSingle";
import GenericPostCoverArtist from "./routes/GenericPostCoverArtist";
import Context from "./components/Context";
import PreLoad from "./routes/PreLoad";
// import GPlayer from "./components/Player/GPlayer";

function App() {
  const { featuredWritersisLoaded } = useContext(Context);
  const LINKURL = "";

  return (
    <>
      <Router>
        {!featuredWritersisLoaded? (
          <>
            <TransitionGroup style={{ overflow: "hidden", display: "initial" }}>
              <CSSTransition classNames={"fade"} timeout={1000}>
                <Switch>
                  <Route exact path={`${LINKURL}/`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <HomePage />
                  </Route>
                  <Route exact path={`${LINKURL}/:d`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <ContactPage />
                  </Route>
                  <Route exact path={`${LINKURL}/blog/:d`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <BlogSingle />
                  </Route>
                  <Route exact path={`${LINKURL}/home/:d`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <GenericPost2 />
                  </Route>
                  <Route exact path={`${LINKURL}/illustration-artist/:d`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <GenericPost2 />
                  </Route>
                  <Route exact path={`${LINKURL}/illustration-artist/single/:d`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <GenericPostCoverArtist />
                  </Route>
                  <Route exact path={`${LINKURL}/blog/outreach/:d`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <BlogSingle />
                  </Route>
                  <Route exact path={`${LINKURL}/blog/articles/:d`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <BlogSingle />
                  </Route>
                  <Route exact path={`${LINKURL}/about/:id`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <GenericPost />
                  </Route>
                  <Route exact path={`${LINKURL}/organisation/:x`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <GenericPost2 />
                  </Route>
                  <Route exact path={`${LINKURL}/The-national-Choreopoem/writer/:x`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <GenericPostArtist />
                  </Route>
                  <Route exact path={`${LINKURL}/The-national-Choreopoem/:x`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <PageSingle />
                  </Route>
                  <Route exact path={`${LINKURL}/translated-books/:x`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <GenericPost2 />
                  </Route>
                  <Route exact path={`${LINKURL}/translated-books/single/:x`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <GenericPostCoverArtist />
                  </Route>
                  <Route exact path={`${LINKURL}/women&children/:d`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <GenericPost2 />
                  </Route>
                  <Route exact path={`${LINKURL}/poems/artists`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <PoemsPage />
                  </Route>
                  <Route exact path={`${LINKURL}/writers/artists`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <ArtistAll />
                  </Route>
                  <Route exact path={`${LINKURL}/now/shopping`}>
                    <NavItemsContextProvider>
                      <Header />
                    </NavItemsContextProvider>
                    <ScrollToTop />
                    <Shop />
                  </Route>
                  <Route exact path={`${LINKURL}/user/signin/`}>
                    <Signin />
                  </Route>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
            <BackToTopBTN />
          </>
        ) : (
          <>
            <PreLoad />
          </>
        )}
        {/* <GPlayer /> */}
      </Router>
    </>
  );
}

export default App;
