import React, { useContext, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Context from "../components/Context";
import Footer from "../section/footer/Footer";
import { PauseOutlined, PlayArrowOutlined } from "@material-ui/icons";
import PlayerContext from "../components/Context/PlayerContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: 10,
  },
  Margin: {
    paddingTop: 111,
    padding: 10,
    // paddingTop: 0,
  },
  details: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 200,
    backgroundColor: "#d4b095",
    backgroundSize: "cover",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  playIcon: {
    height: 38,
    width: 38,
    zIndex: 500,
  },
}));

function PoemsPage() {
  const { AnimeCSS, featuredWriters } = useContext(Context);
  const classes = useStyles();

  const [isAudioPlaying, setisAudioPlaying] = useState([]);
  const [trackedPlayedSongs, settrackedPlayedSongs] = useState([]);

  const { audioContext, analyser } = useContext(PlayerContext);

  //   Refs
  const canvasRef = useRef([]);
  const audioRef = useRef([]);
  const buttonRef = useRef([]);
  buttonRef.current = [];
  audioRef.current = [];
  canvasRef.current = [];

  const Songs = featuredWriters.map(function (params, i) {
    const { acf, _embedded, title, id } = params;
    let indexToString = i.toString();
    let indexString = i;

    const storedPlayedSongs = trackedPlayedSongs.filter(
      (i) => i === indexString
    );

    // const storedButtons = isAudioPlaying?.filter?.((i) => i === indexString);

    const AddSongIndex = () => {
      if (!storedPlayedSongs.includes(i)) {
        settrackedPlayedSongs([...trackedPlayedSongs, i]);
      }
    };

    // const AddButtonIndex = () => {
    //   const a = buttonRef.current[i].dataset.id;
    //   if (!storedButtons.includes?.(i)) {
    //     setisAudioPlaying([...isAudioPlaying, { i: a }]);
    //   }
    // };

    const AudioFunc = () => {
      const canvas = canvasRef.current[i];
      const ctx = canvas.getContext("2d");

      const audioElement = audioRef.current[i];

      const track = audioContext.createMediaElementSource(audioElement);

      track.connect(analyser);
      analyser.connect(audioContext.destination);
      var fbc_array, bars, bar_x, bar_width, bar_height;
      const frameLooper = () => {
        window.requestAnimationFrame(frameLooper);
        fbc_array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(fbc_array);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "#898989";
        bars = 50;
        for (var i = 0; i < bars; i++) {
          bar_x = i * 10;
          bar_width = 20;
          bar_height = -(fbc_array[i] / 6);
          ctx.fillRect(bar_x, canvas.height, bar_width, bar_height);
        }
      };

      return frameLooper();
    };

    const audioSRC = () => {
      const { poem_1, poem_2 } = acf;
      if (poem_1 !== false && poem_2 === false) {
        return poem_1;
      }
      if (poem_1 === false && poem_2 !== false) {
        return poem_2;
      }
      return null;
    };

    const CheckCorrectTitle = () => {
      const { poem_1, poem_2, second_language_poem_title } = acf;
      if (poem_1 !== false && poem_2 === false) {
        return title.rendered.toLowerCase();
      }
      if (poem_1 === false && poem_2 !== false) {
        return second_language_poem_title.toLowerCase();
      }
      return null;
    };

    const Player = ({ audioSRC }) => {
      return (
        <div key={id}>
          {audioSRC !== null ? (
            isAudioPlaying[0]?.i === i ? (
              <IconButton
                style={{
                  backgroundColor: "#d4b095",
                  // color: "#e2ba41",
                }}
                role="button"
                aria-label="pause"
                data-id={i}
                onClick={() => {
                  HandlePause();
                }}
              >
                <PauseOutlined className={classes.playIcon} />
              </IconButton>
            ) : (
              <IconButton
                role="button"
                aria-label="play"
                data-id={i}
                onClick={() => {
                  HandlePlay();
                }}
              >
                <PlayArrowOutlined className={classes.playIcon} />
              </IconButton>
            )
          ) : null}
        </div>
      );
    };

    function HandlePlay() {
      if (indexToString === canvasRef.current[i]?.dataset?.id) {
        AddSongIndex();
        audioRef.current[i].play();

        setisAudioPlaying([{ i }]);
        if (storedPlayedSongs.includes(i)) return;

        if (!storedPlayedSongs.includes(i)) {
          AudioFunc();
        }
      }
    }

    function HandlePause() {
      if (indexToString === canvasRef.current[i].dataset.id) {
        audioRef.current[i].pause();
        setisAudioPlaying([!{ i }]);
      }
    }

    const AddtoRefs = (el) => {
      if (el && !audioRef.current.includes(el)) {
        audioRef.current.push(el);
      }
    };

    const AddtoCanvasRefs = (el) => {
      if (el && !canvasRef.current.includes(el)) {
        canvasRef.current.push(el);
      }
    };

    const AudioViz = () => {
      const VizOn = () => {
        if (isAudioPlaying[0]?.i !== i) {
          return "0";
        } else {
          return "0.2";
        }
      };

      const AudioVizAnimeDelay = () => {
        if (isAudioPlaying[0]?.i !== i) {
          return "0";
        } else {
          return "20s";
        }
      };

      return (
        <canvas
          data-id={i}
          title={title.rendered}
          style={{
            zIndex: 1,
            opacity: VizOn(),
            animationDelay: AudioVizAnimeDelay(),
          }}
          ref={AddtoCanvasRefs}
          id="analyser_render"
        />
      );
    };
    
    return (
      <Card className={classes.root} key={id}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {CheckCorrectTitle()}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {title.rendered.toLowerCase()}
            </Typography>
            <audio
              title={CheckCorrectTitle()}
              ref={AddtoRefs}
              src={audioSRC() || ""}
              crossOrigin="anonymous"
              type="audio/mp3"
            />
          </CardContent>
          <div className={classes.controls}>
            <Player audioSRC={audioSRC()} />
            {AudioViz()}
          </div>
        </div>
        <CardMedia
          className={classes.cover}
          image={
            _embedded["wp:featuredmedia"][0].media_details.sizes.medium
              .source_url
          }
          title={CheckCorrectTitle()}
        />
      </Card>
    );
  });

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{
          background: "transparent",
          height: "100vh",
          margin: "auto",
          alignContent: "center",
          display: "grid",
        }}
      >
        <div className={"LoaderDots"}>
          <span style={{ backgroundColor: "#e2ba41" }} />
          <span style={{ backgroundColor: "#181818" }} />
          <span style={{ backgroundColor: "#e2ba41" }} />
        </div>
      </div>
    );
  }

  const a = () => {
    if (featuredWriters.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className={AnimeCSS()}>
      {a() ? (
        <PreLoader />
      ) : (
        <>
          <div className={classes.Margin}>{Songs}</div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default PoemsPage;
