import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Context from "../components/Context";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import SocialMedia from "../section/socialMedia/SocialMedia";
import Footer from "../section/footer/Footer";
import { Typography } from "@material-ui/core";
import Button from "../components/UI/Form/Button/Button";
import FeaturedWritters from "../section/featuredWriters/FeaturedWritters";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

function PageSingle() {
  const { pathname } = useLocation();

  const {
    AnimeCSS,
    featuredWriters,
    pages,
    isLoaded,
    LINKURL,
    CheckDeviceWidth,
  } = useContext(Context);

  const classes = useStyles();

  let { x } = useParams();

  const cat = [
    "", //0
    "afrikaans", //1
    "", //2
    "xhosa", //3
    "english", //4
    "", //5
    "sesotho", //6
    "ndebele", //7
    "swazi", //8
    "zulu", //9
    "xitsonga", //10
    "sepedi", //11
    "tswana", //12
    "venda", //13
    "cover artist", //14
  ];

  const matchLink = pages.map(function (params) {
    const { slug, title, _embedded, id } = params;
    const ad = slug.replace(/([#-;]+)/g, " ");

    const HeaderImage = () => {
      if (_embedded["wp:featuredmedia"] === undefined || null) return null;
      if (CheckDeviceWidth) {
        return (
          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={_embedded["wp:featuredmedia"][0].source_url}
            alt={""}
          />
        );
      }
      return null;
    };

    const HeaderImageDesktop = () => {
      if (_embedded["wp:featuredmedia"] === undefined || null) return null;
      return _embedded["wp:featuredmedia"][0].source_url;
    };

    const checkMatch = x === ad;
    if (checkMatch) {
      return (
        <div key={id} style={{ height: "100%" }} className={""}>
          <div
            style={{
              height: "30vh",
              position: "relative",
              backgroundAttachment: "fixed",
              backgroundPosition: "center center",
              filter: "grayscale(1)",
              backgroundSize: "cover",
              backgroundImage: `url(${HeaderImageDesktop()})`,
            }}
          >
            <HeaderImage />
            <div
              style={{ position: "absolute", top: "0", left: "0" }}
              className={"BG--OLZ"}
            />
            <h1
              style={{
                position: "absolute",
                display: "block",
                bottom: "-25px",
                fontSize: "5.8rem",
                left: "50%",
                transform: "translateX(-50%)",
                margin: "auto",
                color: "#fff",
                textTransform: "uppercase",
              }}
            >
              {title.rendered}
            </h1>
          </div>
        </div>
      );
    }
    return null;
  });

  const catData = featuredWriters.map(function (catMapData) {
    const { excerpt, categories, id, _embedded, title, acf } = catMapData;

    const catMap = cat.map(function (category, index) {
      const match = index === categories[0];

      if (match) {
        if (index !== undefined) {
          return category;
        }
      }
      return null;
    });

    const a =
      catMap[0] ||
      catMap[1] ||
      catMap[2] ||
      catMap[3] ||
      catMap[4] ||
      catMap[5] ||
      catMap[6] ||
      catMap[7] ||
      catMap[8] ||
      catMap[9] ||
      catMap[10] ||
      catMap[11] ||
      catMap[12] ||
      catMap[13];

    const ad = acf.language.toLowerCase();

    const pathCorrection = () => {
      if (pathname === "/The-national-Choreopoem/english") {
        return "afrikaans";
      }
      return null;
    };

    const PoemCorrection = () => {
      if (pathname !== "/The-national-Choreopoem/english") {
        return acf.poem_two_excerpt;
      } else {
        return excerpt.rendered;
      }
    };

    if (ad === x || a === x || pathCorrection()) {
      const imgURL =
        _embedded["wp:featuredmedia"][0].media_details.sizes.thumbnail
          .source_url;

      function checkImgURL() {
        if (imgURL === null || undefined) {
          return "";
        } else {
          return (
            <img
              key={id}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={imgURL}
              alt={imgURL}
            />
          );
        }
      }
      return (
        <Paper key={id} className={classes.paper}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar>{checkImgURL()}</Avatar>
            </Grid>
            <Grid item xs>
              <Typography gutterBottom variant="h5" component="h2">
                {title.rendered}
              </Typography>
              <div
                className={"MuiTypography-root MuiTypography-body1 pageP"}
                dangerouslySetInnerHTML={{ __html: PoemCorrection() }}
              />
              <Link
                to={{
                  pathname: `${LINKURL}/The-national-Choreopoem/writer/${title.rendered}`,
                  state: { prevPath: pathname },
                }}
              >
                <Button style={{ marginTop: "20px" }} value={"Read more"} />
              </Link>
            </Grid>
          </Grid>
        </Paper>
      );
    }
    return null;
  });

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#181818" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#181818" }} />
      </div>
    );
  }

  return (
    <div className={AnimeCSS()}>
      <div
        style={{ height: "30vh", position: "sticky" }}
        className={`Main--Banner--Wrapper`}
      >
        {isLoaded ? <PreLoader /> : matchLink}
      </div>
      <div className={"Content--Wrapper Help--Line"}>
        {isLoaded ? <PreLoader /> : catData}
      </div>
      <FeaturedWritters />
      <SocialMedia />
      <Footer />
    </div>
  );
}

export default PageSingle;
