// import {
//   ArrowBackIosOutlined,
//   ArrowForwardIosOutlined,
// } from "@material-ui/icons";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Context from "../../components/Context";

function FeaturedWritters() {
  const { featuredWriters, isLoading, LINKURL } = useContext(Context);

  const cat = [
    "", //0
    "afrikaans", //1
    "", //2
    "xhosa", //3
    "english", //4
    "", //5
    "sesotho", //6
    "ndebele", //7
    "swazi", //8
    "zulu", //9
    "xitsonga", //10
    "sepedi", //11
    "tswana", //12
    "venda", //13
    "cover artist", //14
  ];

  const featuredWritersMap = featuredWriters.map((featuredWritersMapData) => {
    const { title, categories, _embedded, id } = featuredWritersMapData;

    const catMap = cat.map(function (category, index) {
      const match = index === categories[1];
      if (match) {
        return (
          <Link key={id} to={`${LINKURL}/The-national-Choreopoem/${category}`}>
            <div
              className={"FeaturedArist--Slider--Group--item"}
              tabIndex={"-1"}
              style={{ width: "100%", display: "inline-block" }}
            >
              <img
                src={_embedded["wp:featuredmedia"][0].source_url}
                alt={title.rendered}
              />
              <div className={"Featured--Writters--Title"}>
                <h1>{category}</h1>
                <div className={"Featured--Writters--Title--Bg"}></div>
              </div>
            </div>
          </Link>
        );
      }
      return null;
    });

    if (_embedded["wp:featuredmedia"] !== undefined || null) {
      return <div key={id}>{catMap}</div>;
    }
    return null;
  });

  const a = () => {
    if (window.innerWidth <= 600) {
      return 2;
    } else {
      return 4;
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: true,
    speed: 3000,
    slidesToShow: a(),
    slidesToScroll: a(),
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  };

  // function NextArrow(props) {
  //   const { style, onClick } = props;
  //   return (
  //     <div
  //       className={"Main--Banner--nav Next slick-arrow slick-next"}
  //       style={{ ...style, left: "unset", right: "0" }}
  //       onClick={onClick}
  //     >
  //       <ArrowForwardIosOutlined color={"inherit"} fontSize={"large"} />
  //     </div>
  //   );
  // }

  // function PrevArrow(props) {
  //   const { style, onClick } = props;
  //   return (
  //     <div
  //       className={"Main--Banner--nav Prev slick-arrow slick-prev"}
  //       style={{ ...style }}
  //       onClick={onClick}
  //     >
  //       <ArrowBackIosOutlined color={"inherit"} fontSize={"large"} />
  //     </div>
  //   );
  // }

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#fff" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
      </div>
    );
  }

  return (
    <div
      style={{
        background:
          "radial-gradient(circle, rgb(215, 166, 130), rgb(121 84 56))",
      }}
    >
      {isLoading ? (
        <PreLoader />
      ) : (
        <>
          <h1 className={"FeaturedArist--Header"}>
            Featured Writers and Artists
          </h1>
          <div className={"FeaturedArist--Slider"}>
            <div className={"FeaturedArist--Slider--Wrapper"}>
              <div className={"FeaturedArist--Slider--Group"}>
                <Slider {...settings}>{featuredWritersMap}</Slider>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default FeaturedWritters;
