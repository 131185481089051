import axios from "axios";
import React, { useEffect, useState } from "react";
import Context from "./Context";

export default function SearchContextProvider(props) {
  const [isSearchOn, setIsSearchOn] = useState(false);

  const [isHelpBTNClicked, setIsHelpBTNClicked] = useState(false);

  const [navItems, setNavItems] = useState([]);
  const [mainBanner, setMainBanner] = useState([]);
  const [pages, setPages] = useState([]);
  const [OutReach, setOutReach] = useState([]);
  const [stats, setStats] = useState([]);
  const [Notification, setNotification] = useState([]);
  const [featuredWriters, setFeaturedWriters] = useState([]);
  const [coverArtist, setcoverArtist] = useState([]);
  const [translatedBooks, settranslatedBooks] = useState([])
  const [FooterMenu, setFooterMenu] = useState([]);
  const [state, setState] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [featuredWritersisLoaded, setfeaturedWritersIsLoaded] = useState(false);
  const [imageModalOn, setimageModalOn] = useState(false);
  const [imageToView, setimageToView] = useState();
  const [imageToViewArray, setimageToViewArray] = useState([]);

  const APIURL = "https://loss-ilahleko.org/wp";
  // const APIURLCORS =
  //   "https://loss-ilahleko-cors.herokuapp.com/https://loss-ilahleko.org/wp";

  const LINKURL = "";

  const API = `${APIURL}/wp-json/wp/v2/pages?per_page=28&_embed`;

  useEffect(() => {
    axios
      .get(`${APIURL}/wp-json/wp/v2/menu`)
      .then(setIsLoaded(true))
      .then((res) => {
        setNavItems(res.data);
        setIsLoaded(false);
      })
      .catch((err) => {});
  }, [APIURL]);

  useEffect(() => {
    axios
      .get(API)
      .then(setIsLoaded(true))
      .then((res) => {
        setPages(res.data);
        setIsLoaded(false);
      })
      .catch((err) => {});
  }, [API]);

  useEffect(() => {
    axios
      .get(`${APIURL}/wp/?rest_route=/wp/v2/statistics&per_page=14&_embed`)
      .then(setIsLoaded(true))
      .then((res) => {
        setStats(res.data);
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  }, [APIURL]);

  useEffect(() => {
    axios
      .get(`${APIURL}/wp/?rest_route=/wp/v2/out_reach_blog&per_page=14&_embed`)
      .then(setIsLoaded(true))
      .then((res) => {
        setOutReach(res.data);
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  }, [APIURL]);

  useEffect(() => {
    axios
      .get(`${APIURL}/wp/?rest_route=/wp/v2/notification_center&_embed`)
      .then(setIsLoaded(true))
      .then((res) => {
        setNotification(res.data);
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  }, [APIURL]);

  useEffect(() => {
    axios
      .get(`${APIURL}/wp-json/wp/v2/footer_menu`)
      .then(setIsLoaded(true))
      .then((res) => {
        setFooterMenu(res.data);
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  }, [APIURL]);

  useEffect(() => {
    axios
      .get(`${APIURL}/?rest_route=/wp/v2/cover_artists&_embed`)
      .then(setIsLoaded(true))
      .then((res) => {
        setcoverArtist(res.data);
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  }, [APIURL]);

  useEffect(() => {
    axios
      .get(`${APIURL}/?rest_route=/wp/v2/translated_books&_embed`)
      .then(setIsLoaded(true))
      .then((res) => {
        settranslatedBooks(res.data);
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  }, [APIURL]);

  useEffect(() => {
    axios
      .get(`${APIURL}/wp/?rest_route=/wp/v2/main_banner&_embed`)
      .then(setIsLoaded(true))
      .then((res) => {
        setMainBanner(res.data);
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(true);
      });
  }, [APIURL]);

  useEffect(() => {
    axios
      .get(`${APIURL}/?rest_route=/wp/v2/featured_writers&per_page=14&_embed`)
      .then(setfeaturedWritersIsLoaded(true))
      .then((res) => {
        setFeaturedWriters(res.data);
        setfeaturedWritersIsLoaded(false);
      })
      .catch((err) => {
        setfeaturedWritersIsLoaded(false);
      });
  }, [APIURL]);

  function handleChangeSIcon() {
    setIsSearchOn(true);
  }
  function handleRemoveChangeSIcon() {
    setIsSearchOn(false);
  }
  function handleHelpBTNClicked() {
    setIsHelpBTNClicked(true);
  }
  function handleRemoveHelpBTNClicked() {
    setIsHelpBTNClicked(false);
  }

  const [showGPlayer, setShowGPlayer] = useState(false);

  const [searchRouter, setSearchRouter] = useState(true);
  const [loadNotification, setLoadNotification] = useState(true);

  const [userActive, setuserActive] = useState([]);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(true);
  };

  const toggleDrawerOff = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(false);
  };

  function handleRemoveLoadNotification() {
    setLoadNotification(false);
  }

  const [states, setstates] = useState(true);

  const CheckDeviceWidth = window.innerWidth <= 600;

  function AnimeCSS() {
    return "animate__animated animate__fadeInUp";
  }
  return (
    <Context.Provider
      value={{
        isSearchOn,
        setIsSearchOn,
        isHelpBTNClicked,
        handleChangeSIcon,
        handleRemoveChangeSIcon,
        handleHelpBTNClicked,
        handleRemoveHelpBTNClicked,
        isLoaded,
        APIURL,
        stats,
        Notification,
        setShowGPlayer,
        showGPlayer,
        OutReach,
        LINKURL,
        toggleDrawer,
        toggleDrawerOff,
        state,
        setState,
        AnimeCSS,
        CheckDeviceWidth,
        searchRouter,
        setSearchRouter,
        mainBanner,
        pages,
        featuredWriters,
        featuredWritersisLoaded,
        navItems,
        FooterMenu,
        handleRemoveLoadNotification,
        loadNotification,
        userActive,
        setuserActive,
        coverArtist,
        translatedBooks,
        states,
        setstates,
        imageModalOn,
        setimageModalOn,
        imageToView,
        setimageToView,
        imageToViewArray,
        setimageToViewArray,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
