// import { CircularProgress } from "@material-ui/core";
import React from "react";
function PreLoader() {
  return (
    <div
      className="loader2"
      style={{
        background: "transparent",
        height: "30px",
        margin: "auto",
        marginTop: "20px",
        color: "#e2ba41",
      }}
    >
      <span style={{ backgroundColor: "#000" }} />
      <span style={{ backgroundColor: "#fff" }} />
      <span style={{ backgroundColor: "#000" }} />
    </div>
  );
}
function PreLoad() {
  return (
    <div className={"preload-wrapper"}>
      <div className={"preload"}>
        <img
          src="https://loss-ilahleko.org/wp/wp-content/uploads/2023/03/Loss-iLahleko-Logo-A.png"
          alt=""
          style={{ width: "350px" }}
        />
        {/* <CircularProgress
          style={{ margin: "auto", marginTop: "20px", color: "#e2ba41" }}
        /> */}
        <PreLoader />
      </div>
    </div>
  );
}

export default PreLoad;
