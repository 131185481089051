import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Context from "../components/Context";

function ADContent() {
  const { LINKURL } = useContext(Context);
  const history = useHistory();
  return (
    <div key={""} className={"Statistics--Slider AD--Wrapper"} tabIndex="-1">
      <div className={"Statistics--Slider--Wrapper--Pay"}>
        <div
          className={"Statistics--Slider--Content--Wrapper Content--Wrapper"}
        >
          <h1 style={{ color: "#d4b095", fontWeight: "bold" }}>
            Loss-ilahleko Books
          </h1>
          <br />
          <h3 className={"Book--AD-Content"}>
            <b>Description:</b> Written by twelve extraordinary South African female writers,
            Loss-iLahleko is a multilingual book series that offers one a journey of learning, awakening and healing,
            through a collection of <Link to="/now/shopping" style={{textDecoration: "underline"}}>translated books and audio books</Link> that hold within them the language of loss.
            A literary journey that moves between English and indigenous languages,
            capturing the beauty, the suffering and the trauma experienced
            by the women and children of South Africa and across the world.
            This work holds the indigenous languages of their mothers,
            whose reality mirrors the perpetual uncertainty and lack of security
            in a land they call home.
            <br />
            <br />
            <i>"Kuthwa amahlathi ayaphela/<br />
            and when you separate us from our bodies/you unsettle the<br />
            trees in the forests/the leaves whisper our names/waiting<br />
            for us to return"</i>
            <br />
            <br />
            Loss-iLahleko is now South Africa's first multilingual and all female written poetry and
            prose book series that gives insight into the shadow pandemic that
            has long gripped South Africa and the globe. With content that is a must know
            for the whole family, <b>buy a book for yourself and sponsor a free copy for a women or child</b>.
            <br />
            <br />
            {/* <b>ISBN:</b> 978-0620-93238-7 <br />
            <b>Recommended Price:</b> R220 <br /> */}
            {/* <span>R200</span> */}
          </h3>
          <button
            onClick={() => {
              history.push(`${LINKURL}/now/shopping`);
            }}
            style={{
              marginTop: "15px",
              color: "#e2ba41",
              backgroundColor: "#c95252",
            }}
          >
            BUY Now!
          </button>
        </div>
      </div>
      <div
        style={{ width: "100%", height: "auto", backgroundColor: "#795438" }}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={
            "https://loss-ilahleko.org/wp/wp-content/uploads/2022/05/Loss-iLahleko-Book-Mockups.jpg"
          }
          alt={""}
        />
      </div>
    </div>
  );
}

export default ADContent;
