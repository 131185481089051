import React, { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Context from "../components/Context";
import SocialMedia from "../section/socialMedia/SocialMedia";
import Footer from "../section/footer/Footer";
import Button from "../components/UI/Form/Button/Button";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FeaturedWritters from "../section/featuredWriters/FeaturedWritters";
import Player from "../components/Player/Player";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "100%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function GenericPostArtist() {
  const { state } = useLocation();
  let history = useHistory();

  const {
    isLoaded,
    featuredWriters,
    mainBanner,
    LINKURL,
    searchRouter,
    AnimeCSS,
  } = useContext(Context);

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let { x } = useParams();

  const cat = [
    "", //0
    "afrikaans", //1
    "", //2
    "xhosa", //3
    "english", //4
    "", //5
    "sesotho", //6
    "ndebele", //7
    "swazi", //8
    "zulu", //9
    "xitsonga", //10
    "sepedi", //11
    "tswana", //12
    "venda", //13
    "cover artist", //14
  ];

  function Notice() {
    return (
      <div
        className="MuiButtonBase-root MuiAccordionSummary-root"
        tabi="0"
        role="button"
        aria-disabled="false"
        aria-expanded="false"
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <div className="MuiAccordionSummary-content">
          <h3 style={{ textAlign: "center" }} className="makeStyles-heading-7">
            For more poetry (written and audio) place your order for your own
            national multilingual anthology coming out in 2021
          </h3>
        </div>
      </div>
    );
  }

  const HeaderContent = featuredWriters.map(function (params, i) {
    const { title, _embedded, id, acf, excerpt, categories } = params;
    const ad = title.rendered;

    const audioSRC = () => {
      const { poem_1, poem_2 } = acf;
      if (poem_1 !== false && poem_2 === false) {
        if (state.prevPath === `${LINKURL}/poems&stories/english`) {
          return poem_1;
        }
        return poem_1;
      } else if (state.prevPath !== `${LINKURL}/poems&stories/english`) {
        if (poem_1 === false && poem_2 !== false) {
          return poem_2;
        }
      }
      return null;
    };

    const contentType = () => {
      if (acf?.select_content_type?.toLowerCase() === "poetry") {
        return "Poem";
      } else {
        return "Story";
      }
    };

    const catMap = cat.map(function (category, i) {
      const matchCat = i === categories[0];
      if (state.prevPath !== undefined) {
        if (
          state?.prevPath === `/poems&stories/${category}` ||
          state?.prevPath === `/writers/artists/${category}` ||
          state?.prevPath === "/poems&stories/english"
        ) {
          if (
            (cat !== undefined && matchCat) ||
            (categories[0] === 29 && categories[1] === i)
          ) {
            return (
              <div key={i} className={"Main--Banner--Slider--Title"}>
                <h1>{category}</h1>
                <span>{contentType()}</span>
              </div>
            );
          }
        }
        if (searchRouter === true) {
          if (state?.prevPath !== "/poems&stories/english") {
            const matchCat = i === categories[1];
            if (cat !== undefined && matchCat) {
              return (
                <div key={i} className={"Main--Banner--Slider--Title"}>
                  <h1>{category}</h1>
                  <span>{contentType()}</span>
                </div>
              );
            }
          }
        }
      }
      return null;
    });

    const checkMatch = x === ad;
    const jongaBaumsebenzisiUsukaphi = () => {
      if (state?.prevPath === `${LINKURL}/`) {
        return false;
      } else if (
        state?.prevPath === `${LINKURL}/poems&stories/english` ||
        (state?.prevPath === `${LINKURL}/` && searchRouter === true) ||
        state?.prevPath === `${LINKURL}/writers/artists/`
      ) {
        return true;
      }
    };
    if (checkMatch) {
      return (
        <div
          key={id}
          className={"Main--Banner--Slider a"}
          tabi={"-1"}
          style={{ width: "100%", display: "inline-block" }}
        >
          <div className={"Content--Wrapper Content--Wrapper--stats"}>
            <div className={"Main--Banner--Content--Wrapper"}>
              <div className={"Main--Banner--Content"}>
                <h1 dangerouslySetInnerHTML={{ __html: title.rendered }} />
                {jongaBaumsebenzisiUsukaphi() ? (
                  <>
                    <div
                      dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
                    />
                  </>
                ) : (
                  <>
                    <p>{acf.poem_two_excerpt}</p>
                  </>
                )}
                <div className={"Main--Banner--Artist--Play--wrapper"}>
                  {audioSRC() !== null && audioSRC() !== false ? (
                    <Player audioSRC={audioSRC()} i={i} acf={acf} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={"Main--Banner--Slider--Artist--Wrapper"}>
            <img src={_embedded["wp:featuredmedia"][0].source_url} alt={""} />
            {catMap}
          </div>
        </div>
      );
    }
    return null;
  });

  const HeaderBannerContent = mainBanner.map(function (params, i) {
    const { title, _embedded, id, excerpt, categories, acf } = params;

    const audioSRC = () => {
      const { poem_1, poem_2, poem_3 } = acf;
      if (poem_1 !== false || poem_2 !== false || poem_3 !== false) {
        return poem_1;
      }
      return null;
    };

    const contentType = () => {
      if (acf?.select_content_type?.toLowerCase() === "poetry") {
        return "Poem";
      } else {
        return "Story";
      }
    };
    const catMap = cat.map(function (category, i) {
      const matchCat = i === categories[0];
      if (matchCat) {
        if (cat !== undefined) {
          return (
            <div key={id} className={"Main--Banner--Slider--Title"}>
              <h1>{category}</h1>
              <span>{contentType()}</span>
            </div>
          );
        }
      }
      return null;
    });

    const checkMatch = x === title.rendered;
    if (checkMatch) {
      return (
        <div
          key={id}
          className={"Main--Banner--Slider"}
          tabi={"-1"}
          style={{ width: "100%", display: "inline-block" }}
        >
          <div className={"Content--Wrapper"}>
            <div className={"Main--Banner--Content--Wrapper"}>
              <div className={"Main--Banner--Content"}>
                <h1 dangerouslySetInnerHTML={{ __html: title.rendered }} />
                <div dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
                <div className={"Main--Banner--Artist--Play--wrapper"}>
                  {audioSRC() !== null ? (
                    <Player audioSRC={audioSRC()} i={i} acf={acf} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={"Main--Banner--Slider--Artist--Wrapper"}>
            <img src={_embedded["wp:featuredmedia"][0].source_url} alt={""} />
            {catMap}
          </div>
        </div>
      );
    }
    return null;
  });

  const PageData = featuredWriters.map(function (catMapData, i) {
    const { title, content, acf, id } = catMapData;

    const checkpage =
      state?.prevPath === `${LINKURL}/poems&stories/english` ||
      (state?.prevPath === `${LINKURL}/poems&stories/english` &&
        searchRouter === true) ||
      state?.prevPath === `${LINKURL}/writers/artists/`;
    const checkOtherpage =
      state.prevPath !== `${LINKURL}/poems&stories/english` ||
      (state.prevPath === `${LINKURL}/` && searchRouter === true) ||
      state.prevPath === `${LINKURL}/writers/artists/`;

    const contentType = () => {
      if (acf?.select_content_type?.toLowerCase() === "poetry") {
        return "Poem";
      } else {
        return "Story";
      }
    };
    if (checkpage) {
      if (title.rendered === x || x === acf.poem_title) {
        return (
          <div key={id} className={"Our--Story--Content Content--Wrapper--stats"}>
            <div
              style={{ marginBottom: "20px" }}
              className={`${classes.root} Artist--Accordion`}
            >
              {acf.poem === "" ? null : (
                <>
                  <h1 style={{ marginBottom: "10px" }}>
                    {title.rendered} {contentType()}
                  </h1>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content b"
                      id="panel1bh-header"
                    >
                      <h1 className={classes.heading}>{acf.poem_title}</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: acf.poem.slice(0, 400),
                          }}
                        />
                        <Button
                          onClick={() => {
                            history.push(`${LINKURL}/now/shopping`);
                          }}
                          value={"to access the full poem order your copy"}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </div>

            <h1>{title.rendered} Biography</h1>
            <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
            <Notice />
          </div>
        );
      }
    } else if (checkOtherpage) {
      if (title.rendered === x || x === acf.poem_title) {
        return (
          <div key={id} className={"Our--Story--Content"}>
            <div
              style={{ marginBottom: "20px" }}
              className={`${classes.root} Artist--Accordion`}
            >
              {acf.poem_two_title === "" &&
              acf.second_language_poem_title === "" ? (
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <h1 className={classes.heading}>{acf.poem_title}</h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    {acf.poem.length < 0 ? null : (
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: acf.poem.slice(0, 298),
                          }}
                        />
                        <Button
                          onClick={() => {
                            history.push(`${LINKURL}/now/shopping`);
                          }}
                          value={"to access the full poem order your copy"}
                        />
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <h1 className={classes.heading}>
                      {acf.second_language_poem_title}
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: acf.second_language_poem,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
            <h1>{title.rendered} Biography</h1>
            <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
            <Notice />
          </div>
        );
      }
    }
    return null;
  });

  const PoemData = mainBanner.map((catMapData) => {
    const { title, content, id } = catMapData;
    if (title.rendered === x) {
      return (
        <div key={id} className={"Our--Story--Content"}>
          <h1>{title.rendered}</h1>
          <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
        </div>
      );
    }
    return null;
  });

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#181818" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#181818" }} />
      </div>
    );
  }

  function checkHeader() {
    if (state.prevPath === `${LINKURL}/` && searchRouter === true) {
      return isLoaded ? <PreLoader /> : HeaderContent;
    }
    if (state.prevPath === `${LINKURL}/`) {
      return isLoaded ? <PreLoader /> : HeaderBannerContent;
    }
    return isLoaded ? <PreLoader /> : HeaderContent;
  }

  function checkBody() {
    if (state.prevPath === `${LINKURL}/` && searchRouter === true) {
      return isLoaded ? <PreLoader /> : PageData;
    }
    if (state.prevPath === `${LINKURL}/`) {
      return isLoaded ? <PreLoader /> : PoemData;
    }
    return isLoaded ? <PreLoader /> : PageData;
  }

  return (
    <div className={AnimeCSS()}>
      <div style={{ position: "sticky" }} className={"Main--Banner--Wrapper"}>
        {checkHeader()}
      </div>
      <div className={"Content--Wrapper"}>
        {isLoaded ? <PreLoader /> : checkBody()}
      </div>
      <FeaturedWritters />
      <SocialMedia />
      <Footer />
    </div>
  );
}
export default GenericPostArtist;
