import React, { useContext, useState } from "react";
// import AlertNotification from "./AlertNotification";
import Nav from "./Nav";
import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
} from "@material-ui/core";
import { GroupOutlined, HomeOutlined } from "@material-ui/icons";
import { LibraryMusicOutlined } from "@material-ui/icons";
import { LocalGroceryStoreOutlined } from "@material-ui/icons";
import MobileNav from "./MobileNav";
import MenuDrawer from "./MenuDrawer";
import { useHistory } from "react-router-dom";
import Context from "../../components/Context";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    backgroundColor: "rgb(137, 137, 137)",
    color: "#fff",
  },
});

function Header(props) {
  const { LINKURL } = useContext(Context);
  const classes = useStyles();
  const [value, setValue] = useState();
  let history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const Redirect = () => {
  //   if (value === "Home") {
  //     history.push(`${LINKURL}/`);
  //     setValue("");
  //   }
  // };
  // Redirect();
  const MobileBottmNav = (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className={classes.root}
    >
      <BottomNavigationAction
        onClick={() => {
          history.push(`${LINKURL}/`);
        }}
        label="Home"
        value="Home"
        icon={<HomeOutlined color="inherit" />}
      />
      <BottomNavigationAction
        onClick={() => {
          history.push(`${LINKURL}/poems/artists`);
        }}
        label="Poems"
        value="Poems"
        icon={<LibraryMusicOutlined color="inherit" />}
      />
      <BottomNavigationAction
        onClick={() => {
          history.push(`${LINKURL}/writers/artists`);
        }}
        label="Writers"
        value="Writers"
        icon={<GroupOutlined color="inherit" />}
      />
      <BottomNavigationAction
        onClick={() => {
          history.push(`${LINKURL}/now/shopping`);
        }}
        label="Shop"
        value="Shop"
        icon={<LocalGroceryStoreOutlined color="inherit" />}
      />
    </BottomNavigation>
  );

  const TopNav = () => {
    if (window.innerWidth <= 600) {
      return <MobileNav />;
    } else {
      return <Nav />;
    }
  };

  const BottomNav = () => {
    if (window.innerWidth <= 600) {
      return MobileBottmNav;
    } else {
      return null;
    }
  };

  // const AlertNotificationF = () => {
  //   if (window.innerWidth <= 600) {
  //     return null;
  //   } else {
  //     return <AlertNotification />;
  //   }
  // };

  return (
    <div
      style={{
        position: "sticky",
        width: "100%",
        zIndex: 99999,
        top: "0px",
      }}
      className={"Header--MenuBG"}
    >
      <div
        style={{
          position: "inherit",
          width: "100%",
          backgroundColor: "rgb(212 176 149)",
          backdropFilter: "blur(5px)",
          zIndex: 99999,
        }}
      >
        <TopNav />
      </div>

      {/* <AlertNotificationF /> */}
      <BottomNav />
      <MenuDrawer />
    </div>
  );
}

export default Header;
