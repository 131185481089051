import { Link } from "react-router-dom";
import React, { useContext } from "react";
import Context from "../../components/Context";

function OurStoryExcept() {
  const { pages, isLoaded, LINKURL } = useContext(Context);

  const findPage = pages.map(function (findMatchingPage) {
    const { id, excerpt, title, slug } = findMatchingPage;
    if (id === 106) {
      return (
        <div key={id} className={"Our--Story--Wrapper"}>
          <h1
            style={{ textAlign: "left" }}
            dangerouslySetInnerHTML={{ __html: title.rendered }}
          />
          <p dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
          <Link role="button" to={`${LINKURL}/about/${slug}`}>
            <button style={{ backgroundColor: "rgb(212 176 149)" }}>Read more</button>
          </Link>
        </div>
      );
    }
    return null;
  });

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#181818" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#181818" }} />
      </div>
    );
  }

  return (
    <div className={"Content--Wrapper"}>
      {isLoaded ? (
        <PreLoader />
      ) : (
        <div className={"Our--Story--Content--Wrapper Content--Wrapper--stats"} style={{margin:"auto"}}>
          {/* <div className={"Our--Story--Wrapper--image d-none"}>
            <img src={`${LINKURL}/images/Loss-ilahleko-Logo-2.png`} alt={""} />
          </div> */}
          {findPage}
        </div>
      )}
    </div>
  );
}

export default OurStoryExcept;
