import { makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { useLocation, useParams } from "react-router";
import Context from "../components/Context";
import FeaturedWritters from "../section/featuredWriters/FeaturedWritters";
import Footer from "../section/footer/Footer";
import SocialMedia from "../section/socialMedia/SocialMedia";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "100%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function GenericPostCoverArtist() {
  const { isLoaded, coverArtist, AnimeCSS,translatedBooks } = useContext(Context);

  const classes = useStyles();
  
  const { pathname } = useLocation();


  let { d,x } = useParams();

  const HeaderContent = coverArtist.map(function (params, i) {
    const { title, _embedded, id, excerpt } = params;

    return (
      <div
        key={id}
        className={"Main--Banner--Slider a"}
        tabi={"-1"}
        style={{ width: "100%", display: "inline-block" }}
      >
        <div className={"Content--Wrapper"}>
          <div className={"Main--Banner--Content--Wrapper"}>
            <div className={"Main--Banner--Content"}>
              <h1 dangerouslySetInnerHTML={{ __html: title.rendered }} />
              <div dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
            </div>
          </div>
        </div>
        <div className={"Main--Banner--Slider--Artist--Wrapper"}>
          <img src={_embedded["wp:featuredmedia"][0].source_url} alt={""} />
          <div className={"Main--Banner--Slider--Title"}>
            <h1>Cover</h1>
            <span>Artist</span>
          </div>
        </div>
      </div>
    );
  });

  const HeaderContent2 = translatedBooks.map(function (params, i) {
    const { title, _embedded, id, excerpt } = params;

    return (
      <div
        key={id}
        className={"Main--Banner--Slider a"}
        tabi={"-1"}
        style={{ width: "100%", display: "inline-block" }}
      >
        <div className={"Content--Wrapper"}>
          <div className={"Main--Banner--Content--Wrapper"}>
            <div className={"Main--Banner--Content"}>
              <h1 dangerouslySetInnerHTML={{ __html: title?.rendered }} />
              <div dangerouslySetInnerHTML={{ __html: excerpt?.rendered }} />
            </div>
          </div>
        </div>
        <div className={"Main--Banner--Slider--Artist--Wrapper"} style={{position:"relative"}}>
          <div className={"Main--Banner--Slider--Title"} style={{position:"initial", width:"100%"}}>
          <img src={_embedded["wp:featuredmedia"][0]?.source_url} alt={""} style={{position:"initial", width:"100%"}} />
          </div>
        </div>
      </div>
    );
  });

  const PageData = coverArtist.map(function (catMapData, i) {
    const { title, content, id } = catMapData;

    if (title.rendered === d) {
      return (
        <div key={id} className={"Our--Story--Content"}>
          <div
            style={{ marginBottom: "20px" }}
            className={`${classes.root} Artist--Accordion`}
          ></div>

          <h1>{title.rendered} Biography</h1>
          <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
        </div>
      );
    }

    return null;
  });

  const PageData2 = translatedBooks.map(function (catMapData, i) {
    const { title, content, id } = catMapData;

    if (title.rendered === x) {
      return (
        <div key={id} className={"Our--Story--Content"}>
          <div
            style={{ marginBottom: "20px" }}
            className={`${classes.root} Artist--Accordion`}
          ></div>

          <h1>Translated Books: {title.rendered}</h1>
          <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
        </div>
      );
    }

    return null;
  });

  function PreLoader() {
    return (
      <div
        className="loader2"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={{ backgroundColor: "#181818" }} />
        <span style={{ backgroundColor: "#e2ba41" }} />
        <span style={{ backgroundColor: "#181818" }} />
      </div>
    );
  }

  console.log(x ===pathname.split("/")[3])

  return (
    <div className={AnimeCSS()}>
      <div style={{ position: "sticky" }} className={"Main--Banner--Wrapper"}>
        {x === pathname.split("/")[3] ? HeaderContent2:HeaderContent}
      </div>
      <div className={"Content--Wrapper"}>
        {isLoaded ? <PreLoader /> : x === pathname.split("/")[3] ?PageData2:PageData}
      </div>
      <FeaturedWritters />
      <SocialMedia />
      <Footer />
    </div>
  );
}

export default GenericPostCoverArtist;
