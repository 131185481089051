import { ArrowForwardIosOutlined } from "@material-ui/icons";
import React, { useCallback, useState } from "react";

function BackToTopBTN() {
  // const { setShowGPlayer } = useContext(Context);
  const [showBackToTop, setShowBackToTop] = useState(false);

  const ScrollPos = useCallback(() => {
    window.onscroll = function () {
      // ab();
      ab2();
    };

    // function ab() {
    //   return window.scrollY > window.outerHeight / 2
    //     ? setShowGPlayer(true)
    //     : setShowGPlayer(false);
    // }
    function ab2() {
      return window.scrollY > window.outerHeight / 2
        ? setShowBackToTop(true)
        : setShowBackToTop(false);
    }
  }, []);

  ScrollPos();
  return showBackToTop ? (
    <span
      onClick={() => {
        window.scrollTo(0, 0);
      }}
      className={"SliderBTN BackToTopBTN"}
      style={{
        transform: "translateY(2px)",
        color: "#fff",
        borderRadius: "100%",
      }}
    >
      <ArrowForwardIosOutlined color={"inherit"} size={"medium"} />
    </span>
  ) : null;
}

export default BackToTopBTN;
